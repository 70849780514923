import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  color?: string
  bgColor?: string
  size?: number
}

export const StyledObjectIcon = styled.div<{ $size?: number; $color?: string; $bgColor?: string }>`
  width: ${({ $size }) => $size || 32}px;
  height: ${({ $size }) => $size || 32}px;
  color: ${({ $color }) => $color || 'inherit'};
  background-color: ${({ $bgColor }) => $bgColor || 'transparent'};

  svg {
    width: 100%;
    height: 100%;
  }
`

export const ObjectIcon: FC<Props> = ({ color, bgColor, size }) => {
  return (
    <StyledObjectIcon $size={size} $color={color} $bgColor={bgColor}>
      <svg width="800px" height="800px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M2.1 3.1c0.2 1.3 0.4 1.6 0.4 2.9 0 0.8-1.5 1.5-1.5 1.5v1c0 0 1.5 0.7 1.5 1.5 0 1.3-0.2 1.6-0.4 2.9-0.3 2.1 0.8 3.1 1.8 3.1s2.1 0 2.1 0v-2c0 0-1.8 0.2-1.8-1 0-0.9 0.2-0.9 0.4-2.9 0.1-0.9-0.5-1.6-1.1-2.1 0.6-0.5 1.2-1.1 1.1-2-0.3-2-0.4-2-0.4-2.9 0-1.2 1.8-1.1 1.8-1.1v-2c0 0-1 0-2.1 0s-2.1 1-1.8 3.1z"
        ></path>
        <path
          fill="currentColor"
          d="M13.9 3.1c-0.2 1.3-0.4 1.6-0.4 2.9 0 0.8 1.5 1.5 1.5 1.5v1c0 0-1.5 0.7-1.5 1.5 0 1.3 0.2 1.6 0.4 2.9 0.3 2.1-0.8 3.1-1.8 3.1s-2.1 0-2.1 0v-2c0 0 1.8 0.2 1.8-1 0-0.9-0.2-0.9-0.4-2.9-0.1-0.9 0.5-1.6 1.1-2.1-0.6-0.5-1.2-1.1-1.1-2 0.2-2 0.4-2 0.4-2.9 0-1.2-1.8-1.1-1.8-1.1v-2c0 0 1 0 2.1 0s2.1 1 1.8 3.1z"
        ></path>
      </svg>
    </StyledObjectIcon>
  )
}
