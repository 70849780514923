import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button, SelectInput } from 'authflow-ui-engine'
import { PopupTitle, LoaderWrapper, AddUsersToGroupModalContainer, AddGroupInput } from '../GroupsTablePage.styles'
import { useTheme } from 'styled-components'
import { StoreContext } from '../../../components/App'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { SelectInputWrapper } from '../../../styles/generic.styles'
import { addGroupsToPermission } from '../../../communication/Permissions'
import { Group } from '../../../types/Group'
import { Popup } from '../../../components/Popup'

interface AddPermissionToGroupModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  group: Group
}

export const AddPermissionToGroupModal: FC<AddPermissionToGroupModalProps> = observer(
  ({ isOpen, setIsOpen, group }) => {
    const theme = useTheme()
    const store = useContext(StoreContext)

    const { permSelectList, isGroupActionLoading } = store.CommunicationState

    const [selectedPerm, setSelectedPerm] = useState<string>('')

    const handleAddPermToGroup = async () => {
      store.CommunicationState.setGroupActionLoading(true)

      const perm = selectedPerm.split('-')

      await addGroupsToPermission({
        resourceType: perm[0],
        resourceName: perm[1],
        operation: perm[2],
        allowedGroups: [group.id],
      })
      store.CommunicationState.setGroupActionLoading(false)

      setIsOpen(false)
    }

    useEffect(() => {
      store.CommunicationState.fetchAllDataForInputs()
    }, [])

    return (
      <Popup
        width="600px"
        visibility={isOpen}
        handleVisibility={setIsOpen}
        {...theme.popup}
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        {isGroupActionLoading ? (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        ) : (
          <AddUsersToGroupModalContainer>
            <PopupTitle>Select permission to add to group</PopupTitle>

            <AddGroupInput>
              <label>Permission:</label>
              <SelectInputWrapper>
                <SelectInput
                  {...theme.selectInput}
                  options={permSelectList}
                  onChange={(e) => {
                    setSelectedPerm(e.value)
                  }}
                  height="40px"
                />
              </SelectInputWrapper>
            </AddGroupInput>

            <Button
              type="button"
              {...theme.buttons.commonButton}
              width="180px"
              height="40px"
              onClick={handleAddPermToGroup}
            >
              Add permission
            </Button>
          </AddUsersToGroupModalContainer>
        )}
      </Popup>
    )
  },
)
