import { FC } from 'react'
import { DesktopMenu } from './DesktopMenu'
import { MobileMenu } from './MobileMenu'

export const Menu: FC = () => {
  return (
    <>
      <DesktopMenu />
      <MobileMenu />
    </>
  )
}
