import { Button, Colors, Json, Theme } from './theme'
import { commonButton, commonTheme } from './common.theme'

const colors: Colors = {
  primary: '#F2A3B3',
  primary2: '#CC8593',
  secondary: '#F2A3B3',
  secondary2: '#FFFFFF',
  secondary3: '#433D49',
  secondary4: '#1C1A1F',
  secondary5: '#2A0062',
  secondary6: '#7F7B87',
  secondary7: '#CC8593',
  secondary8: '#491296',
  secondary9: '#F2A3B3',
  secondary10: '#F2A3B3',
  tertiary: '#FFFFFF',
  tertiary2: '#1C1A1F',
  tertiary3: '#252229',
  quaternary: '#252229',
  quaternary2: '#2A0062',
  quaternary3: '#FFFFFF',
  quaternary4: '#7F7B87',
  quaternary5: '#F2A3B3',
  quinary: '#413F45',
  senary: '#7F7B87',
  septenary: '#FFFFFF',
  septenary2: '#98959F',
  septenary3: '#F2A3B3',
  scrollTrackColor: '#7F7B87',
  scrollHandleColor: '#F2A3B3',
  error: '#D63649',
}

const button: Button = {
  ...commonButton,
  bgColor: colors.primary,
  color: colors.secondary3,
  bgColorHover: colors.primary,
  colorHover: colors.secondary3,
  bgColorDisabled: colors.quinary,
  colorDisabled: colors.septenary,
}

const createTransactionClearButton: Button = {
  ...commonButton,
  width: '115px',
  height: '40px',
  fontSize: '16px',
  color: colors.secondary,
  bgColor: 'transparent',
  border: `1px solid transparent`,
  colorHover: colors.secondary,
  borderHover: `1px solid ${colors.secondary}`,
  bgColorHover: 'transparent',
  textDecoration: 'underline',
}

const createTransactionCreateButton: Button = {
  ...commonButton,
  width: '115px',
  height: '40px',
  fontSize: '16px',
  color: colors.tertiary2,
  bgColor: colors.secondary,
  border: colors.secondary,
  colorHover: colors.tertiary2,
  borderHover: colors.secondary,
  bgColorHover: colors.secondary7,
  colorDisabled: colors.tertiary2,
  bgColorDisabled: colors.secondary,
}

const createTransactionTabButton: Button = {
  ...commonButton,
  width: 'auto',
  height: 'auto',
  padding: '12px 25px',
  borderRadius: '16px 16px 0 0',
  bgColor: 'transparent',
  color: colors.secondary,
  bgColorHover: colors.secondary,
  colorHover: colors.quaternary,
}

const createTransactionTabButtonActive: Button = {
  ...commonButton,
  width: 'auto',
  height: 'auto',
  padding: '12px 25px',
  borderRadius: '16px 16px 0 0',
  bgColor: colors.tertiary3,
  color: colors.secondary,
  bgColorHover: colors.tertiary3,
  colorHover: colors.secondary,
}

const copyPayloadButton: Button = {
  ...commonButton,
  width: '215px',
  widthMobile: '215px',
  height: '29px',
  heightMobile: '29px',
  bgColor: 'transparent',
  border: `1px solid ${colors.septenary3}`,
  color: colors.septenary3,
  fontSize: '12px',
  bgColorHover: colors.septenary3,
  colorHover: colors.tertiary3,
}

const json: Json = {
  textColor: '#F2EDFE',
  textSecondaryColor: '#c6c4c4',
  headlineColor: '#F2EDFE',
  backgroundColor: '#252229',
  lineHeight: '22px',
  linePadding: '0px 3px 0px 4px',
  borderDefault: 'rgba(255, 255, 255, 0.23)',
  borderFocused: '#05aeca',
  borderInvalid: '#9d190f',
  selectionMatch: '#ae38a782',
  activeLineGutter: '#303438',
  activeLine: '#303438',
  activeSelection: '#1872b9',
  dividerColor: '#EEE',
  spacing: '4px',
  contentPadding: `calc(4px / 2)`,
  borderRadius: '8px',
}

//   default: '#F2EDFE',
//   string: '#b39ad4',
//   number: '#E02569',
//   colon: '#00AC82',
//   keys: '#087e57',
//   keys_whiteSpace: '#E02569',
//   primitive: '#087e57',
//   background: '#252229',
//   background_warning: '#252229',

export const darkTheme: Theme = {
  name: 'dark',
  ...commonTheme,
  colors: {
    ...colors,
  },
  calendar: {
    borderRadius: '5px',
    borderColor: 'rgb(229, 228, 233)',
    backgroundColor: '#252229',
    backgroundColorDisabled: '#1C1A1F',
    dayColor: '#FFFFFF',
    tile: {
      borderRadius: '100px',
      padding: '10px 6.6667px',
      hover: {
        backgroundColor: 'rgba(242, 163, 179, 0.5)',
      },
      disabled: {
        backgroundColor: '#433D49',
      },
      now: {
        backgroundColor: 'rgb(42, 0, 98)',
        color: 'white',
        hover: {
          backgroundColor: 'rgba(42, 0, 98, 0.7)',
        },
      },
      active: {
        backgroundColor: 'rgb(242, 163, 179)',
        color: 'rgb(42, 0, 98)',
        hover: {
          backgroundColor: 'rgba(242, 163, 179, 0.7)',
        },
      },
      hasActive: {
        backgroundColor: 'rgba(242, 163, 179)',
        color: 'rgb(42, 0, 98)',
        hover: {
          backgroundColor: 'rgba(242, 163, 179, 0.5)',
        },
      },
    },
    navigation: {
      button: {
        borderRadius: '100px',
        hover: {
          backgroundColor: 'rgba(242, 163, 179, 0.5)',
        },
        disabled: {
          backgroundColor: '#433D49',
        },
      },
    },
  },
  createTransaction: {
    mainBgColor: '#1C1A1F',
    mainBorderColor: '#cbc6d3',
    mainColor: '#cbc6d3',
    mainHeaderColor: '#FFFFFF',
    objectEditorBgColor: '#252229',
    tabActiveBgColor: '#252229',
    tabActiveColor: '#F2A3B3',
    tabBgColor: 'transparent',
    tabColor: '#F2A3B3',
    tabContentColor: '#F2A3B3',
    resultsBgColor: '#252229',
    resultsActiveBgColor: '#1C1A1F',
    resultsValueColor: '#F2A3B3',
    resultsPropertyColor: '#FFFFFF',
    resultsLinkColor: '#F2A3B3',
    resultsSuccessStatusColor: '#60A340',
    resultsErrorStatusColor: '#D63649',

    resultsOpenButtonBgColor: '#F2A3B3',
    resultsOpenButtonColor: '#252229',
    resultsOpenButtonBorderColor: '#F2A3B3',
    resultsSliderTrackColor: '#7F7B87',
    resultsSliderHandleColor: '#F2A3B3',
    clearButton: createTransactionClearButton,
    createButton: createTransactionCreateButton,
    tabButton: createTransactionTabButton,
    tabButtonActive: createTransactionTabButtonActive,
    copyPayloadButton: copyPayloadButton,
  },

  loadingSpinner: {
    primary: '#F2A3B3',
    bgColor: '#1C1A1F',
    bgIframe: '#252229',
  },
  sidebar: {
    boxShadow: '0px 0px 10px 0px #7F7B87',
  },
  buttons: {
    commonButton: {
      ...button,
    },
    closeModalButton: {
      ...button,
      width: '32px',
      height: '32px',
      bgColor: colors.quaternary5,
      bgColorHover: colors.quaternary5,
      padding: '0',
    },
    clearFiltersButton: {
      ...button,
      height: '37px',
      margin: '21px 0 0',
      padding: '0 20px',
      bgColor: colors.primary,
      color: colors.secondary3,
      border: 'none',
      widthMobile: '100%',
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.primary2,
    },
    groupingDataButton: {
      ...button,
      color: colors.secondary2,
      bgColor: 'inherit',
      padding: '0',
      bgColorHover: 'inherit',
    },
    loadMoreButton: {
      ...button,
      width: 'auto',
      height: 'auto',
      padding: '10px 20px',
      border: `1px solid ${colors.quinary}`,
      bgColor: colors.quaternary,
      color: colors.secondary2,
      borderHover: `1px solid ${colors.quinary}`,
      bgColorHover: colors.quinary,
      colorHover: colors.secondary2,
      bgColorDisabled: 'transparent',
    },
    goBackButton: {
      ...button,
      height: '24px',
      heightMobile: '24px',
      width: 'auto',
      widthMobile: 'auto',
      fontSize: '11px',
      padding: '0 20px',
      color: colors.secondary2,
      bgColor: 'transparent',
      border: `1px solid ${colors.septenary}`,
      bgColorHover: colors.septenary,
      colorHover: colors.secondary5,
      borderHover: `1px solid ${colors.septenary}`,
      margin: '7px 15px 0 0',
    },
    paginationButton: {
      ...button,
      width: 'auto',
      widthMobile: 'auto',
      height: 'auto',
      padding: '10px 20px',
      borderRadius: '24px',
      border: `1px solid ${colors.quinary}`,
      bgColor: colors.quaternary,
      color: colors.secondary2,
      borderHover: `1px solid ${colors.quinary}`,
      bgColorHover: colors.quinary,
      colorHover: colors.secondary2,
      bgColorDisabled: 'transparent',
    },
    downloadButton: {
      ...button,
      bgColor: colors.primary,
      width: 'auto',
      widthMobile: 'auto',
      height: 'auto',
      margin: '10px 0 0',
      padding: '9px 10px',
      color: colors.secondary3,
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.primary2,
      borderRadiusHover: '0',
    },
    popupPrimaryButton: {
      ...button,
      bgColor: colors.primary,
      width: 'auto',
      widthMobile: 'auto',
      height: 'auto',
      margin: '10px 0 0',
      padding: '9px 10px',
      color: colors.secondary3,
      colorDisabled: colors.septenary,
      bgColorDisabled: colors.quinary,
      bgColorHover: colors.primary2,
      borderRadiusHover: '0',
    },
    popupDeclineButton: {
      ...button,
      bgColor: colors.quaternary,
      width: 'auto',
      widthMobile: 'auto',
      height: 'auto',
      margin: '10px 0 0',
      padding: '9px 10px',
      color: colors.tertiary,
      bgColorHover: colors.septenary2,
      borderRadiusHover: '0',
    },
  },
  textInput: {
    height: '40px',
    color: colors.secondary2,
    border: `1px solid ${colors.quinary}`,
    bgColor: 'inherit',
    padding: '6px 6px 6px 12px',
    borderRadius: '5px',
    colorFocus: colors.secondary2,
    bgColorFocus: 'inherit',
    borderDisabled: `1px solid ${colors.quinary}`,
    bgColorDisabled: colors.quinary,
    colorDisabled: colors.septenary2,
  },
  popup: {
    wrapBgColor: 'rgba(229, 228, 233, 0.4)',
    color: colors.secondary2,
    bgColor: colors.quaternary,
    border: undefined,
    borderRadius: '16px',
    dividingBorder: '0',
    mobileWidth: '',
    mobileMargin: '',
    mobilePadding: '',
    headerColor: colors.secondary4,
    headerBgColor: colors.quaternary5,
    closeTop: '20px',
    closeRight: '30px',
  },
  selectInput: {
    borderRadius: '5px',
    borderWidth: '1px',
    color: colors.secondary2,
    borderColor: colors.quinary,
    colorDisabled: colors.septenary2,
    backgroundColor: colors.quaternary,
    borderColorFocus: colors.secondary2,
    colorFocus: colors.quaternary,
    backgroundColorFocus: colors.primary2,
    colorSelected: colors.quaternary,
    backgroundColorSelected: colors.secondary,
    menuMarginTop: '1px',
    menuBorderWidth: '1px',
    danger: 'rgba(226,64,40,1)',
    dangerLight: 'rgba(226,64,40,0.5)',
  },
  json: json,
}
