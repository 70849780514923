import styled from 'styled-components'

export const LinkListWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 16px;
    justify-content: center;
  }
`

export const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  max-width: 400px;
  min-width: 300px;
`

export const LinkRow = styled.a`
  display: flex;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 12px;
  border-radius: 8px;
  font-weight: 500 !important;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondary};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary4};
    cursor: pointer;
  }

  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:focus {
    outline: none;
  }

  &:visited {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const LinkTitle = styled.span`
  display: flex;
  align-items: center;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    border-radius: 4px;
  }
`

export const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 0;
`
