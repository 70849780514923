import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button, TextInput, SelectInput } from 'authflow-ui-engine'
import { LoaderWrapper, AddGroupForm, AddGroupInput, PopupTitle } from '../PermissionsTablePage.styles'
import { useTheme } from 'styled-components'
import { StoreContext } from '../../../components/App'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { SelectInputWrapper, TextInputWrapper } from '../../../styles/generic.styles'
import { createPermission } from '../../../communication/Permissions'
import { Popup } from '../../../components/Popup'

interface CreatePermissionModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export const CreatePermissionModal: FC<CreatePermissionModalProps> = observer(({ isOpen, setIsOpen }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { isPermActionLoading, groupSelectList } = store.CommunicationState

  const [resourceType, setResourceType] = useState<'Config' | 'Secret' | ''>('')
  const [resourceName, setResourceName] = useState('')
  const [operation, setOperation] = useState<'Write' | 'Use' | ''>('')
  const [selectedGroups, setSelectedGroups] = useState<string[]>([])

  const createPermissionFc = async (e) => {
    e.preventDefault()

    if (!resourceType || !resourceName || !operation || !selectedGroups.length) return

    store.CommunicationState.setPermActionLoading(true)

    await createPermission({
      resourceType,
      resourceName,
      operation,
      allowedGroups: selectedGroups,
    })

    store.CommunicationState.setPermActionLoading(false)
    setIsOpen(false)
  }

  useEffect(() => {
    store.CommunicationState.fetchAllDataForInputs()
  }, [])

  useEffect(() => {
    switch (resourceType) {
      case 'Config':
        setOperation('Write')
        break
      case 'Secret':
        setOperation('Use')
        break
      default:
        break
    }
  }, [resourceType])

  return (
    <Popup
      width="400px"
      visibility={isOpen}
      handleVisibility={setIsOpen}
      {...theme.popup}
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      {isPermActionLoading ? (
        <LoaderWrapper>
          <LoadingSpinner width="60px" {...theme.loadingSpinner} />
        </LoaderWrapper>
      ) : (
        <AddGroupForm onSubmit={createPermissionFc}>
          <PopupTitle>Create permission</PopupTitle>

          <AddGroupInput>
            <label>resourceType</label>
            <SelectInputWrapper>
              <SelectInput
                {...theme.selectInput}
                options={[
                  { label: 'Config', value: 'Config' },
                  { label: 'Secret', value: 'Secret' },
                ]}
                onChange={(e) => {
                  setResourceType(e.value)
                }}
                height="40px"
              />
            </SelectInputWrapper>
          </AddGroupInput>

          <AddGroupInput>
            <label>resourceName</label>
            <TextInputWrapper {...theme.textInput}>
              <TextInput
                name="name"
                value={resourceName}
                type="text"
                onChange={(e) => {
                  setResourceName(e)
                }}
                onError={() => {}}
                {...theme.textInput}
                autoComplete="off"
              />
            </TextInputWrapper>
          </AddGroupInput>

          <AddGroupInput>
            <label>operation</label>
            <TextInputWrapper {...theme.textInput}>
              <TextInput
                name="name"
                value={operation}
                type="text"
                onChange={(e) => {
                  setOperation(e)
                }}
                onError={() => {}}
                {...theme.textInput}
                autoComplete="off"
                disabled
              />
            </TextInputWrapper>
          </AddGroupInput>

          <AddGroupInput>
            <label>Allowed groups</label>
            <SelectInputWrapper>
              <SelectInput
                {...theme.selectInput}
                options={groupSelectList}
                isMulti
                onChange={(e) => {
                  setSelectedGroups(e.map((el) => el.value))
                }}
                height="40px"
              />
            </SelectInputWrapper>
          </AddGroupInput>

          <Button type="button" {...theme.buttons.commonButton} width="100px" height="40px">
            Create
          </Button>
        </AddGroupForm>
      )}
    </Popup>
  )
})
