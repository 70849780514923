import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const StyledTable = styled.table<{ $width?: number }>`
  //remove gaps
  border-collapse: collapse;
  border-spacing: 0;
  width: ${({ $width }) => $width + 'px' || 'auto'};
  max-width: 100%;
  margin: 0;
  padding: 0;
`

export const TableHead = styled.thead`
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
  white-space: nowrap;

  tr:hover {
    background-color: ${({ theme }) => theme.colors.tertiary3};
    cursor: default;
  }
`

export const TableRow = styled.tr<{ $error?: boolean }>`
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme, $error }) => ($error ? theme.colors.error + '44' : theme.colors.tertiary2)};
    cursor: pointer;
  }

  background-color: ${({ $error, theme }) => ($error ? theme.colors.error + '44' : 'transparent')};
`

export const TableCell = styled.td<{ $width?: number }>`
  color: ${({ theme }) => theme.colors.secondary2};
  padding: 24px 8px;

  border-top: 2px solid ${({ theme }) => theme.colors.tertiary2};

  width: ${({ $width }) => $width + 'px' || '100%'};
`

export const TableFooter = styled.tfoot``

export const TableBody = styled.tbody``

export const TableHeaderCell = styled.th<{ $width?: number }>`
  padding: 16px 8px;
  border-right: 4px solid ${({ theme }) => theme.colors.tertiary2};

  &:last-child {
    border-right: none;
  }
`

export const TablePagination = styled.div``
