import { FC, useContext, useEffect, useState } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import {
  PageContainer,
  PageTitle,
  LoaderWrapper,
  PageHeader,
  PopupTitle,
  PopupParagraph,
  PopupButtonWrapper,
  PopupUserDetails,
  ConfirmationPopupWrapper,
  SwitchWrapper,
  FiltersWrapper,
} from './UsersTablePage.styles'
import { Page, Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { SearchInput } from '../../styles/generic.styles'
import { Table } from '../../components/Table/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { StoreContext } from '../../components/App'
import { observer } from 'mobx-react'
import { User } from '../../types/User'
import { CloseIcon } from '../../components/Icons/CloseIcon'
import { SwitchInput } from '../../components/SwitchInput'
import { deleteUser, getUsersList } from '../../communication/Users'
import AddUserModal from './modals/AddUserModal'
import { RefreshButton } from '../../components/RefreshButton'
import { Popup } from '../../components/Popup'

const columnHelper = createColumnHelper<User>()

export const UsersTablePage: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { usersList, isUserActionLoading } = store.CommunicationState

  const [isFetching, setIsFetching] = useState(false)

  const [filteredUsers, setFilteredUsers] = useState<User[]>(usersList)
  const [search, setSearch] = useState('')
  const [includeDeleted, setIncludeDeleted] = useState(false)

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [userDataToDelete, setUserDataToDelete] = useState<User | null>()

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)

  const fetchUsers = async () => {
    setIsFetching(true)

    const { data } = await getUsersList({
      includeDeleted,
    })

    store.CommunicationState.setUsersList(data.users)

    setIsFetching(false)
  }

  const deleteUserFc = async (userId: string) => {
    store.CommunicationState.setUserActionLoading(true)

    await deleteUser(userId)

    store.CommunicationState.setUserActionLoading(false)
    setIsConfirmModalOpen(false)
  }

  useEffect(() => {
    if (isUserActionLoading) return

    fetchUsers()
  }, [isUserActionLoading, includeDeleted])

  useEffect(() => {
    setFilteredUsers(
      usersList.filter((user) => {
        return (
          user?.id?.includes(search) ||
          user?.name?.includes(search) ||
          user?.email?.includes(search) ||
          user?.entraUserId?.includes(search)
        )
      }),
    )
  }, [usersList, search])

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor('email', {
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor('id', {
      size: 50,
      minSize: 50,
      maxSize: 50,
      header: () => <span>Actions</span>,
      cell: ({ row }) =>
        row?.original?.isDeleted ? (
          <></>
        ) : (
          <Button
            {...theme.buttons.commonButton}
            width="90px"
            height="36px"
            onClick={() => {
              console.log('row', row.original)
              setUserDataToDelete(row.original)
              setIsConfirmModalOpen(true)
            }}
          >
            Delete
          </Button>
        ),
    }),
  ]

  return (
    <Page $paddingMobile="64px 0">
      <PageHeader>
        <PageTitle>
          Users <RefreshButton onRefresh={() => fetchUsers()} />
        </PageTitle>
        <Button
          {...theme.buttons.commonButton}
          width="100px"
          widthMobile="100px"
          height="40px"
          onClick={() => {
            setIsAddUserModalOpen(true)
          }}
        >
          Add User
        </Button>
      </PageHeader>
      <Spacer size={10}></Spacer>

      <FiltersWrapper>
        <SearchInput
          $isDarkTheme={theme.name === 'dark'}
          type="search"
          value={search}
          disabled={isFetching}
          placeholder="Search by every column value"
          id="filterInput"
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />

        <SwitchWrapper>
          <label>Include deleted:</label>
          <SwitchInput checked={includeDeleted} onChange={setIncludeDeleted} />
        </SwitchWrapper>
      </FiltersWrapper>

      <Spacer size={20} $desktopOnly />

      <PageContainer>
        {!isFetching ? (
          <Table
            data={filteredUsers}
            columns={columns}
            // rowUlr={'user'}
            // rowOnClickProperty={'clientId'}
          />
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30} />

      <AddUserModal isOpen={isAddUserModalOpen} setIsOpen={setIsAddUserModalOpen} />

      <Popup
        width="500px"
        visibility={isConfirmModalOpen}
        handleVisibility={setIsConfirmModalOpen}
        {...theme.popup}
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsConfirmModalOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        <ConfirmationPopupWrapper>
          <PopupTitle>Delete user</PopupTitle>
          <PopupParagraph>
            Are you sure you want to <span>delete</span> selected user?
          </PopupParagraph>
          <PopupUserDetails>
            <label>Name</label>
            <span>{userDataToDelete?.name}</span>
            <label>Email</label>
            <span>{userDataToDelete?.email}</span>
            <label>User ID</label>
            <span>{userDataToDelete?.id}</span>
          </PopupUserDetails>
          <PopupButtonWrapper>
            <Button
              type="button"
              {...theme.buttons.commonButton}
              width="100px"
              height="40px"
              onClick={() => {
                setUserDataToDelete(null)
                setIsConfirmModalOpen(false)
              }}
            >
              Cancel
            </Button>

            <Button
              type="button"
              {...theme.buttons.commonButton}
              width="100px"
              height="40px"
              onClick={() => {
                deleteUserFc(userDataToDelete?.id)
              }}
            >
              Delete
            </Button>
          </PopupButtonWrapper>
        </ConfirmationPopupWrapper>
      </Popup>
    </Page>
  )
})
