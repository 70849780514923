import { FC, useContext, useEffect, useState, MouseEvent as ReactMouseEvent } from 'react'
import { LoadingSpinner, Button } from 'authflow-ui-engine'
import { PageContainer, PageTitle, LoaderWrapper, PageHeader, FiltersWrapper } from './PermissionsTablePage.styles'
import { Page, Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { SearchInput } from '../../styles/generic.styles'
import { Table } from '../../components/Table/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { StoreContext } from '../../components/App'
import { observer } from 'mobx-react'
import { CreatePermissionModal } from './modals/CreatePermissionModal'
import { getPermissionsList } from '../../communication/Permissions'
import { RefreshButton } from '../../components/RefreshButton'
import { Permission, PermissionListItem } from '../../types/Permissions'
import { DeletePermissionModal } from './modals/DeletePermissionModal'
import { ManagePermissionModal } from './modals/ManagePermissionModal'

const columnHelper = createColumnHelper<PermissionListItem>()

export const PermissionsTablePage: FC = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const { permissionsList, isPermActionLoading } = store.CommunicationState

  const [isFetching, setIsFetching] = useState(false)

  const [permDataToDelete, setPermDataToDelete] = useState<Permission | null>(null)
  const [filteredPermissions, setFilteredPermissions] = useState<Permission[]>(permissionsList)
  const [search, setSearch] = useState('')

  const [isManagePermissionModalOpen, setIsManagePermissionModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isCreatePermissionModalOpen, setIsCreatePermissionModalOpen] = useState(false)

  const handlePermDelete = (perm: Permission) => {
    setPermDataToDelete(perm)
    setIsConfirmModalOpen(true)
  }

  const handleManagePermissionModalOpening = (permId: string, e: ReactMouseEvent<HTMLTableCellElement, MouseEvent>) => {
    const perm = permissionsList.find((perm) => perm?.id === permId)

    if ((e.target as Element).nodeName === 'BUTTON') {
      handlePermDelete(perm)
    } else {
      store.CommunicationState.setCurrentPermission(perm)
      setIsManagePermissionModalOpen(true)
    }
  }

  const fetchPermissions = async () => {
    setIsFetching(true)
    const { data } = await getPermissionsList()

    store.CommunicationState.setPermissionsList(data?.permissions)

    setIsFetching(false)
  }

  useEffect(() => {
    if (!isPermActionLoading) {
      fetchPermissions()
    }
  }, [isPermActionLoading])

  useEffect(() => {
    setFilteredPermissions(
      permissionsList.filter((perm) => {
        return (
          perm?.resourceName?.includes(search) ||
          perm?.resourceType?.includes(search) ||
          perm?.operation?.includes(search)
        )
      }),
    )
  }, [permissionsList, search])

  const columns = [
    columnHelper.accessor('resourceName', {
      header: () => <span>Resource Name</span>,
    }),
    columnHelper.accessor('resourceType', {
      header: () => <span>Resource Type</span>,
    }),
    columnHelper.accessor('operation', {
      header: () => <span>Operation</span>,
    }),
    columnHelper.accessor('id', {
      id: 'id',
      size: 100,
      minSize: 100,
      maxSize: 100,
      header: () => <span>Actions</span>,
      cell: () => (
        <Button {...theme.buttons.commonButton} width="90px" height="36px">
          Delete
        </Button>
      ),
    }),
  ]

  return (
    <Page $paddingMobile="64px 0">
      <PageHeader>
        <PageTitle>
          Permissions <RefreshButton onRefresh={() => fetchPermissions()} />
        </PageTitle>
        <Button
          {...theme.buttons.commonButton}
          width="180px"
          widthMobile="180px"
          height="40px"
          onClick={() => {
            setIsCreatePermissionModalOpen(true)
          }}
        >
          Create permission
        </Button>
      </PageHeader>
      <Spacer size={10} />

      <FiltersWrapper>
        <SearchInput
          $isDarkTheme={theme.name === 'dark'}
          type="search"
          value={search}
          disabled={isFetching}
          placeholder="Search by every column value"
          id="filterInput"
          onChange={(e) => {
            setSearch(e.target.value)
          }}
        />
      </FiltersWrapper>

      <Spacer size={20} $desktopOnly />

      <PageContainer>
        {!isFetching ? (
          <Table
            data={filteredPermissions}
            columns={columns}
            rowClickMode="function"
            rowClickFunction={handleManagePermissionModalOpening}
            rowOnClickProperty={'id'}
          />
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30} />

      <CreatePermissionModal isOpen={isCreatePermissionModalOpen} setIsOpen={setIsCreatePermissionModalOpen} />

      <DeletePermissionModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        permDataToDelete={permDataToDelete}
        setPermissionDataToDelete={setPermDataToDelete}
      />

      <ManagePermissionModal isOpen={isManagePermissionModalOpen} setIsOpen={setIsManagePermissionModalOpen} />
    </Page>
  )
})
