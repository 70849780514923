import { toast } from 'react-toastify'
import { axiosInstance } from '../methods/axiosConfig'
import { encodeString } from '../methods/encode'
import { GetGroupList, Group, ModifyUsersInGroup, PostGroup } from '../types/Group'

export const getGroupsList = async () => {
  try {
    return await axiosInstance.get<GetGroupList>(`/groups`)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const getGroup = async (groupId: string) => {
  try {
    return await axiosInstance.get<Group>(`/groups/${encodeString(groupId)}`)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const addGroup = async (group: PostGroup) => {
  try {
    return await axiosInstance.post(`/groups`, group)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const deleteGroup = async (groupId: string) => {
  try {
    return await axiosInstance.delete(`/groups/${encodeString(groupId)}`)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const addUsersToGroup = async ({ groupId, memberIds }: ModifyUsersInGroup) => {
  try {
    return await axiosInstance.put(`/groups/${encodeString(groupId)}/members`, { memberIds })
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const deleteUsersFromGroup = async ({ groupId, memberIds, forceInvalidateSessions }: ModifyUsersInGroup) => {
  try {
    return await axiosInstance.delete(`/groups/${encodeString(groupId)}/members`, {
      data: { memberIds, forceInvalidateSessions },
    })
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}
