import { observer } from 'mobx-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { LoadingSpinner, Button, SelectInput, TextInput } from 'authflow-ui-engine'
import { Page } from '../../styles/generic.styles'
import { PageTitle } from '../../styles/objectViewPages.styles'
import { useTheme } from 'styled-components'
import { SelectInputWrapper, TextArea, TextInputWrapper } from '../../styles/generic.styles'
import { getOnCalls, postCreateIncident, GetOnCallsResponse } from '../../communication/Ops'
import { Form, PeopleOnCallContainer, PeopleOnCallRow, PeopleOnCallTitle } from './CreateIncidentPage.styles'
import { toast } from 'react-toastify'

const selectOptions = [
  { label: 'P1', value: 'P1' },
  { label: 'P2', value: 'P2' },
  { label: 'P3', value: 'P3' },
  { label: 'P4', value: 'P4' },
  { label: 'P5', value: 'P5' },
]

export const CreateIncidentPage: FC = observer(() => {
  const theme = useTheme()

  const [isLoading, setIsLoading] = useState(false)
  const [onCalls, setOnCalls] = useState<GetOnCallsResponse[]>([])

  const [message, setMessage] = useState('')
  const [description, setDescription] = useState('')
  const [priority, setPriority] = useState<'P1' | 'P2' | 'P3' | 'P4' | 'P5'>('P1')

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()

      if (isLoading || !message || !description) return

      setIsLoading(true)

      await postCreateIncident({
        message,
        description,
        priority,
      })

      setMessage('')
      setDescription('')
      setPriority('P1')

      toast.success('Incident created successfully')

      setIsLoading(false)
    },
    [message, description, priority],
  )

  const getDataOfOnCallPeople = async () => {
    const onCallsData = await getOnCalls()
    setOnCalls(onCallsData)
  }

  useEffect(() => {
    void getDataOfOnCallPeople()
  }, [])

  return (
    <Page>
      <PageTitle>Create Incident</PageTitle>

      {onCalls?.length && (
        <PeopleOnCallContainer>
          <PeopleOnCallTitle>People on call</PeopleOnCallTitle>

          {onCalls?.map((onCall, index) => (
            <PeopleOnCallRow key={index}>
              <div>{onCall.schedule}</div>

              {onCall?.participants?.map((participant, index) => <div key={index}>{participant}</div>)}
            </PeopleOnCallRow>
          ))}
        </PeopleOnCallContainer>
      )}

      <Form onSubmit={handleSubmit}>
        <label>Message</label>
        <TextInputWrapper>
          <TextInput
            {...theme.textInput}
            onChange={(e) => {
              setMessage(e)
            }}
            value={message}
            height="40px"
            type="text"
            onError={() => {}}
            disabled={isLoading}
          />
        </TextInputWrapper>

        <label>Description</label>
        <TextInputWrapper {...theme.textInput}>
          <TextArea
            name="description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
            autoComplete="off"
            disabled={isLoading}
          />
        </TextInputWrapper>

        <label>Priority</label>
        <SelectInputWrapper {...theme.textInput}>
          <SelectInput
            name="priority"
            onChange={(e) => {
              setPriority(e.value)
            }}
            onError={() => {}}
            {...theme.selectInput}
            autoComplete="off"
            options={selectOptions}
            isDisabled={isLoading}
          />
        </SelectInputWrapper>

        <Button {...theme.buttons.commonButton} width="140px" height="40px">
          {isLoading ? (
            <LoadingSpinner width="20px" thickness={3} {...theme.loadingSpinner} bgIframe={theme.colors.primary} />
          ) : (
            'Create'
          )}
        </Button>
      </Form>
    </Page>
  )
})
