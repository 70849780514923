import styled from 'styled-components'
import { Animatable } from '../../styles/generic.styles'
import { MenuButton } from '@szhsin/react-menu'

export const StyledMenu = styled.div`
  padding: 32px 4px;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.quaternary};
  width: 100%;
  height: 100vh;
  min-height: 52px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: 80px calc(100% - 80px - 96px) 96px;
  grid-template-columns: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }

  ${Animatable};
`

export const StyledLogo = styled.div<{ $height?: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: ${({ $height }) => $height || '100%'};

  svg {
    height: 100%;
  }
`

export const MenuNav = styled.nav`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 8px;
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  padding: 0 4px 0 8px;
  margin: 16px 0 32px 0;
  max-height: calc(100vh - 32px - 50px - 80px - 32px);
`

export const NavCategoryTitle = styled.div`
  width: 100%;
  font-size: 20px;

  color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
`

export const MenuFooter = styled.div`
  justify-self: end;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const MenuDropdownButton = styled(MenuButton)`
  width: 100%;
  height: 32px;
  border: none;
  background-color: inherit;
  appearance: none;
  padding: 0 16px;
  border-radius: 24px;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary2};

  &:hover {
    cursor: pointer;
  }
`

export const PathSymbol = styled.div`
  padding-bottom: 4px;
`

export const MenuItemWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding-left: 8px;

  min-height: 36px;
  max-height: 36px;
  height: 36px;
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`
