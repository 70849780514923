export const jsonEditorValue = {
  responseType: 'plain',
  encryptionKid: 'test.common.jwk.v.1',
  resultWebhookUrls: ['https://webhook.site/internal-dev-tf'],
  enableAccessCode: true,
  'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
  services: {
    'klarna-ref': ['klarna-payment-settings', 'klarna.sandbox'],
    'tink-ref': ['tink.sandbox'],
    'kontomatik-ref': ['kontomatik.sandbox'],
  },
  clientId: 'dtp-dev.pink',
  type: 'Client',
}

export const jsonEditorReferenceMapping = [
  {
    unresolvedPath: 'current',
    resolvedValue: {
      responseType: 'plain',
      encryptionKid: 'test.common.jwk.v.1',
      resultWebhookUrls: ['https://webhook.site/internal-dev-tf'],
      enableAccessCode: true,
      'certs-ref-secret': 'secrets/onboarding/dtp-dev.pink',
      services: {
        klarna: {
          klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
          klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
          paymentSettings: {
            to: {
              iban: 'DE74202201000050503014',
              holder_name: 'Signicat AS',
              holder_address: {
                street_address: 'Beddingen 16',
                postalcode: '7042',
                city: 'Trondheim',
                country: 'NO',
              },
              bic: 'DNBADEHX',
              bank_name: 'DNB Bank ASA - Filiale Deutschland',
              bank_address: {
                street_address: 'Neuer wall 72',
                postalcode: '20354',
                city: 'Hamburg',
                country: 'DE',
              },
            },
            amount: {
              amount: 1,
              currency: 'EUR',
            },
          },
        },
        tink: {
          'apiKey-ref-secret': 'tink-api-key/sandbox',
          payment: {
            amount: 0.01,
            currency_id: 'EUR',
            purpose: 'Verification payment',
            recipient_iban: 'NO9098022290150',
            recipient_holder: 'Signicat AS',
            language: 'de',
          },
          skipSenderNameValidation: true,
          shouldMockSenderHolder: true,
          retryAttempts: 1,
        },
        kontomatik: {
          piiStorage: 'tf-dev-03-kontomatik-pii-storage',
          bankStatementUpload: true,
          providerBaseUrl: 'https://test.api.kontomatik.com',
          providerClientId: 'signicat-test',
          'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
        },
      },
    },
  },
  {
    unresolvedPath: 'current/services/klarna-ref',
    resolvedValue: {
      klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
      klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
      paymentSettings: {
        to: {
          iban: 'DE74202201000050503014',
          holder_name: 'Signicat AS',
          holder_address: {
            street_address: 'Beddingen 16',
            postalcode: '7042',
            city: 'Trondheim',
            country: 'NO',
          },
          bic: 'DNBADEHX',
          bank_name: 'DNB Bank ASA - Filiale Deutschland',
          bank_address: {
            street_address: 'Neuer wall 72',
            postalcode: '20354',
            city: 'Hamburg',
            country: 'DE',
          },
        },
        amount: {
          amount: 1,
          currency: 'EUR',
        },
      },
    },
  },
  {
    unresolvedPath: 'current/services/tink-ref',
    resolvedValue: {
      'apiKey-ref-secret': 'tink-api-key/sandbox',
      payment: {
        amount: 0.01,
        currency_id: 'EUR',
        purpose: 'Verification payment',
        recipient_iban: 'NO9098022290150',
        recipient_holder: 'Signicat AS',
        language: 'de',
      },
      skipSenderNameValidation: true,
      shouldMockSenderHolder: true,
      retryAttempts: 1,
    },
  },
  {
    unresolvedPath: 'current/services/kontomatik-ref',
    resolvedValue: {
      piiStorage: 'tf-dev-03-kontomatik-pii-storage',
      bankStatementUpload: true,
      providerBaseUrl: 'https://test.api.kontomatik.com',
      providerClientId: 'signicat-test',
      'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
    },
  },
  {
    unresolvedPath: 'current/services/klarna-ref[0]',
    resolvedValue: {
      clientId: 'klarna-payment-settings',
      type: 'Entity',
      version: 1,
      paymentSettings: {
        amount: {
          amount: 1,
          currency: 'EUR',
        },
        to: {
          bank_address: {
            country: 'DE',
            street_address: 'Neuer wall 72',
            city: 'Hamburg',
            postalcode: '20354',
          },
          bank_name: 'DNB Bank ASA - Filiale Deutschland',
          holder_address: {
            country: 'NO',
            street_address: 'Beddingen 16',
            city: 'Trondheim',
            postalcode: '7042',
          },
          bic: 'DNBADEHX',
          holder_name: 'Signicat AS',
          iban: 'DE74202201000050503014',
        },
      },
    },
  },
  {
    unresolvedPath: 'current/services/klarna-ref[1]',
    resolvedValue: {
      clientId: 'klarna.sandbox',
      type: 'Entity',
      klarnaTokenSecretName: 'klarna-api-tokens/sandbox',
      version: 1,
      klarnaBaseUrl: 'https://api.openbanking.playground.klarna.com',
    },
  },
  {
    unresolvedPath: 'current/services/tink-ref[0]',
    resolvedValue: {
      clientId: 'tink.sandbox',
      type: 'Entity',
      version: 1,
      retryAttempts: 1,
      'apiKey-ref-secret': 'tink-api-key/sandbox',
      shouldMockSenderHolder: true,
      payment: {
        amount: 0.01,
        language: 'de',
        recipient_holder: 'Signicat AS',
        purpose: 'Verification payment',
        currency_id: 'EUR',
        recipient_iban: 'NO9098022290150',
      },
      skipSenderNameValidation: true,
    },
  },
  {
    unresolvedPath: 'current/services/kontomatik-ref[0]',
    resolvedValue: {
      clientId: 'kontomatik.sandbox',
      type: 'Entity',
      version: 1,
      providerBaseUrl: 'https://test.api.kontomatik.com',
      piiStorage: 'tf-dev-03-kontomatik-pii-storage',
      bankStatementUpload: true,
      'providerXApiKey-ref-secret': 'kontomatik-x-api-key/sandbox',
      providerClientId: 'signicat-test',
    },
  },
]
