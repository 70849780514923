import { observer } from 'mobx-react'
import { FC, useCallback, useState } from 'react'
import { JSONEditor } from '../../components/JSONEditor/JSONEditor'

import { CodeMirrorOnChange } from '@ui-schema/kit-codemirror'
import { jsonEditorValue, jsonEditorReferenceMapping } from '../../constants/mocks/debugPage'
import { Page } from '../../styles/generic.styles'

const dataValue = jsonEditorValue

const onClickProperty = (propertyName) => {
  console.log('Clicked property:', propertyName)
  // Add your custom logic here
}

export const DebugPage: FC = observer(() => {
  const [value, setValue] = useState(JSON.stringify(dataValue, undefined, 4))

  const onChange: CodeMirrorOnChange = useCallback(
    (v, newValue) => {
      if (!v.docChanged || typeof newValue !== 'string') {
        return
      }
      setValue(newValue)
    },
    [setValue],
  )

  const isValidJSON = (jsonString: string): string => {
    try {
      JSON.parse(jsonString)
      return 'yes'
    } catch (e) {
      return 'no'
    }
  }

  return (
    <Page>
      <div>
        <h1>Debug Page</h1>
        <p>This is a page for debugging purposes.</p>
      </div>
      <br />
      <div>isValidJSON: {isValidJSON(value)}</div>
      <br />
      <JSONEditor
        value={value}
        onChange={onChange}
        widgetOnClick={onClickProperty}
        widgetPropNameList={jsonEditorReferenceMapping.map((item) => item.unresolvedPath)}
      />
    </Page>
  )
})
