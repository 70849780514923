import { observer } from 'mobx-react'
import { FC } from 'react'
import { PageTitle } from '../../styles/objectViewPages.styles'
import { Page } from '../../styles/generic.styles'
import { LinkList, LinkRow, LinkTitle, Icon, SectionTitle, LinkListWrap } from './LinksListPage.styles'
import {
  ShortcutLogo,
  OnboardingDashboardLogo,
  OpsGenieLogo,
  ZendeskLogo,
  ConfluenceLogo,
  SalesforceLogo,
  AzureLogo,
  GitlabLogo,
} from './Icons'
import AWSLogo from './Logos/aws.png'
import DatadogLogo from './Logos/datadog.png'
import SignicatITLogo from './Logos/signicatIT.png'
import SpaceliftLogo from './Logos/spacelift.png'
import EidvLogo from './Logos/eidv.png'
import { SignicatLogo } from '../../components/Brandings/SignicatLogo'

//eslint-disable-next-line
const links = [
  {
    icon: <OnboardingDashboardLogo />,
    name: 'Onboarding Dashboard',
    url: 'https://onboarding-dashboard.identity.signicat.global/',
  },
  {
    icon: <ShortcutLogo />,
    name: 'Shortcut',
    url: 'https://app.shortcut.com/signicat',
  },
  {
    icon: <img src={AWSLogo} />,
    name: 'AWS',
    url: 'https://attempt-identity.signin.aws.amazon.com/console',
  },
  {
    icon: <img src={DatadogLogo} />,
    name: 'Datadog',
    url: 'https://app.datadoghq.eu/account/login/id/eef18ee0-8e79-11ec-8431-da7ad0900005',
  },
  {
    icon: <img src={SignicatITLogo} />,
    name: 'Signicat internal-it SD',
    url: 'https://signicat.atlassian.net/servicedesk/customer/portals',
  },
  {
    icon: <img src={SpaceliftLogo} />,
    name: 'Spacelift',
    url: 'https://signicat-global-solutions.app.spacelift.io',
  },
  {
    icon: <img src={EidvLogo} />,
    name: 'ElectronicID RA sandbox',
    url: 'https://etrust-sandbox.electronicid.eu/registry2/login',
  },
  {
    icon: <img src={EidvLogo} />,
    name: 'ElectronicID RA live',
    url: 'https://etrust-live.electronicid.eu/registry2/login',
  },
  {
    icon: <SignicatLogo />,
    name: 'Onboarding documentation',
    url: 'https://signicatinternal:Z10broChu7@documentation.prod.identity.signicat.global/onboarding',
  },
  {
    icon: <OpsGenieLogo />,
    name: 'OpsGenie',
    url: 'https://signicat.app.opsgenie.com/teams/dashboard/34617119-b20e-498e-b7b6-b672aa037e98/main',
  },
  {
    icon: <ZendeskLogo />,
    name: 'Zendesk',
    url: 'https://signicat.zendesk.com/agent',
  },
  {
    icon: <ConfluenceLogo />,
    name: 'Confluence',
    url: 'https://signicat.atlassian.net/wiki/spaces/PINK/overview?homepageId=194084176082',
  },
  {
    icon: <SignicatLogo />,
    name: 'Signicat dashboard',
    url: 'https://dashboard.signicat.com/',
  },
]

export const LinksListPage: FC = observer(() => {
  return (
    <Page>
      <PageTitle>Links</PageTitle>
      <LinkListWrap>
        <LinkList>
          <SectionTitle>Pink</SectionTitle>

          <LinkRow href="https://onboarding-dashboard.identity.signicat.global/">
            <Icon>
              <OnboardingDashboardLogo />
            </Icon>
            <LinkTitle>Onboarding Dashboard</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicatinternal:Z10broChu7@documentation.prod.identity.signicat.global/onboarding">
            <Icon>
              <SignicatLogo />
            </Icon>
            <LinkTitle>Onboarding documentation</LinkTitle>
          </LinkRow>
        </LinkList>

        <LinkList>
          <SectionTitle>Pink Saas</SectionTitle>

          <LinkRow href="https://app.datadoghq.eu/dashboard/dby-p3c-sf3/onboarding-dashboard">
            <Icon>
              <img src={DatadogLogo} />
            </Icon>
            <LinkTitle>Datadog</LinkTitle>
          </LinkRow>

          <LinkRow href="https://attempt-identity.signin.aws.amazon.com/console">
            <Icon>
              <img src={AWSLogo} />
            </Icon>
            <LinkTitle>AWS</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.app.opsgenie.com/teams/dashboard/34617119-b20e-498e-b7b6-b672aa037e98/main">
            <Icon>
              <OpsGenieLogo />
            </Icon>
            <LinkTitle>OpsGenie</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat-global-solutions.app.spacelift.io">
            <Icon>
              <img src={SpaceliftLogo} />
            </Icon>
            <LinkTitle>Spacelift</LinkTitle>
          </LinkRow>

          <LinkRow href="https://portal.azure.com/?quickstart=True#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Overview">
            <Icon>
              <AzureLogo />
            </Icon>
            <LinkTitle>Entra ID</LinkTitle>
          </LinkRow>

          <LinkRow href="https://app.shortcut.com/signicat">
            <Icon>
              <ShortcutLogo />
            </Icon>
            <LinkTitle>Shortcut</LinkTitle>
          </LinkRow>
        </LinkList>

        <LinkList>
          <SectionTitle>Signicat</SectionTitle>

          <LinkRow href="https://signicat.zendesk.com/agent">
            <Icon>
              <ZendeskLogo />
            </Icon>
            <LinkTitle>Zendesk</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.atlassian.net/wiki/spaces/PINK/overview?homepageId=194084176082">
            <Icon>
              <ConfluenceLogo />
            </Icon>
            <LinkTitle>Confluence</LinkTitle>
          </LinkRow>

          <LinkRow href="https://dashboard.signicat.com/">
            <Icon>
              <SignicatLogo />
            </Icon>
            <LinkTitle>Signicat dashboard</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.atlassian.net/servicedesk/customer/portals">
            <Icon>
              <img src={SignicatITLogo} />
            </Icon>
            <LinkTitle>Signicat internal-it SD</LinkTitle>
          </LinkRow>

          <LinkRow href="https://signicat.my.salesforce.com/">
            <Icon>
              <SalesforceLogo />
            </Icon>
            <LinkTitle>Salesforce</LinkTitle>
          </LinkRow>

          <LinkRow href="https://gitlab.com/signicat">
            <Icon>
              <GitlabLogo />
            </Icon>
            <LinkTitle>Gitlab</LinkTitle>
          </LinkRow>
        </LinkList>

        <LinkList>
          <SectionTitle>ElectronicId</SectionTitle>

          <LinkRow href="https://etrust-sandbox.electronicid.eu/registry2/login">
            <Icon>
              <img src={EidvLogo} />
            </Icon>
            <LinkTitle>ElectronicID RA sandbox</LinkTitle>
          </LinkRow>

          <LinkRow href="https://etrust-live.electronicid.eu/registry2/login">
            <Icon>
              <img src={EidvLogo} />
            </Icon>
            <LinkTitle>ElectronicID RA live</LinkTitle>
          </LinkRow>
        </LinkList>
      </LinkListWrap>
    </Page>
  )
})
