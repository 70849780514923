import { Button } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import { useTheme } from 'styled-components'
import { useLocation } from 'wouter'
import { BackArrowIcon } from './Icons/BackArrow'
import { FC } from 'react'

interface BackButtonProps {
  backUrl?: string
  priority?: 'props' | 'params'
}

export const BackButton: FC<BackButtonProps> = observer(({ backUrl, priority = 'params' }) => {
  // eslint-disable-next-line
  const [location, setLocation] = useLocation()
  const theme = useTheme()

  const query = new URLSearchParams(window.location.search)

  const handleBack = () => {
    const returnUrlQuery = query.get('returnUrl')

    if (returnUrlQuery?.length && !backUrl) {
      setLocation(returnUrlQuery)
    } else if (returnUrlQuery?.length && priority === 'params') {
      setLocation(returnUrlQuery)
    } else if (backUrl) {
      setLocation(backUrl)
    }
  }

  return (
    <Button
      {...theme.buttons.commonButton}
      height="36px"
      width="36px"
      heightMobile="36px"
      widthMobile="36px"
      padding="0"
      paddingMobile="0"
      onClick={handleBack}
      bgColor="transparent"
      bgColorHover="transparent"
      color={theme.colors.secondary2}
      colorHover={theme.colors.secondary2 + '88'}
    >
      <BackArrowIcon size="36px" />
    </Button>
  )
})
