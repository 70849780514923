import { FC, PropsWithChildren, useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'wouter'
import { Button } from 'authflow-ui-engine'
import { axiosInstance } from '../../methods/axiosConfig'
import { StoreContext } from '../App'
import {
  StyledMenu,
  MenuNav,
  MenuFooter,
  NavCategoryTitle,
  MenuItemWrapper,
  PathSymbol,
  BottomWrapper,
} from './DesktopMenu.styles'
import { LogoutIcon } from '../Icons/LogoutIcon'
import { LogsIcon } from '../Icons/LogsIcon'
import { StyledLogo } from './DesktopMenu.styles'
import { MenuItem } from './MenuItem'
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher'
import { MenuItemIcon } from './MenuItemIcon'
import { MenuItemName } from './MenuItemName'
import { SignicatLogo } from '../Brandings/SignicatLogo'
import { ThemeIcon } from '../ThemeSwitcher/ThemeIcon'
import { useTheme } from 'styled-components'
import { STAGE } from '../../constants/envs'
import { SettingsIcon } from '../Icons/SettingsIcon'
import { PermsIcon } from '../Icons/PermsIcon'
import { PinkhubLogo } from '../Brandings/PinkhubLogo'
import { StarIcon } from '../Icons/StarIcon'

export const DesktopMenu: FC<PropsWithChildren> = observer(() => {
  const theme = useTheme()
  const store = useContext(StoreContext)
  const { currentUser } = store.CommunicationState

  //eslint-disable-next-line
  const [location, setLocation] = useLocation()

  const toggleTheme = () => {
    store.AppState.toggleTheme()
  }

  const logout = useCallback(async () => {
    await axiosInstance.get(`logout`)

    store.CommunicationState.setAuthorization(false)

    setLocation('/login')
  }, [])

  const isAllowedToManageConfig = currentUser?.capabilities?.includes('CLIENT_CONFIG_MANAGEMENT')

  const isAllowedToManageAccess = currentUser?.capabilities?.includes('ACCESS_MANAGEMENT')

  const isAlowedIncidentManagement = currentUser?.capabilities?.includes('INCIDENT_MANAGEMENT')

  return (
    <>
      <StyledMenu>
        <StyledLogo>
          <PinkhubLogo />
        </StyledLogo>

        <MenuNav>
          {isAllowedToManageConfig && (
            <>
              <NavCategoryTitle>
                <MenuItemIcon>
                  <LogsIcon />
                </MenuItemIcon>
                Configurations
              </NavCategoryTitle>

              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path={['/', '/configurations/clients', '/configurations/clients/*']}>
                  <MenuItemName>Clients</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>

              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path={['/configurations/entities', '/configurations/entities/*']}>
                  <MenuItemName>Entities</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>
            </>
          )}

          {isAllowedToManageAccess && (
            <>
              <NavCategoryTitle>
                <MenuItemIcon>
                  <PermsIcon />
                </MenuItemIcon>
                Access Management
              </NavCategoryTitle>

              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path="/access-management/users">
                  <MenuItemName>Users</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>
              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path="/access-management/groups">
                  <MenuItemName>Groups</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>

              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path="/access-management/permissions">
                  <MenuItemName>Permissions</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>
            </>
          )}

          <>
            <NavCategoryTitle>
              <MenuItemIcon>
                <StarIcon />
              </MenuItemIcon>
              Tools
            </NavCategoryTitle>

            {isAlowedIncidentManagement && (
              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path="/tools/create-incident">
                  <MenuItemName>Create incident</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>
            )}
            <MenuItemWrapper>
              <PathSymbol>└</PathSymbol>
              <MenuItem path="/tools/links">
                <MenuItemName>Links</MenuItemName>
              </MenuItem>
            </MenuItemWrapper>
          </>

          {STAGE === 'local' && (
            <>
              <NavCategoryTitle>
                <MenuItemIcon>
                  <SettingsIcon />
                </MenuItemIcon>
                Debug
              </NavCategoryTitle>

              <MenuItemWrapper>
                <PathSymbol>└</PathSymbol>
                <MenuItem path="/debug">
                  <MenuItemName>page 1</MenuItemName>
                </MenuItem>
              </MenuItemWrapper>
            </>
          )}
        </MenuNav>

        <MenuFooter>
          <StyledLogo $height="48px">
            <SignicatLogo />
          </StyledLogo>

          <BottomWrapper>
            <ThemeSwitcher onClick={toggleTheme}>
              <ThemeIcon />
              <MenuItemName>{theme.name}</MenuItemName>
            </ThemeSwitcher>

            <Button onClick={logout} {...theme.buttons.commonButton} height="32px" width="110px">
              <MenuItemIcon>
                <LogoutIcon />
              </MenuItemIcon>
              <MenuItemName>{'Logout'}</MenuItemName>
            </Button>
          </BottomWrapper>
        </MenuFooter>
      </StyledMenu>
    </>
  )
})
