import { FC } from 'react'
import { useTheme } from 'styled-components'
import { CloseIcon } from './Icons/CloseIcon'
import { JSONEditor } from './JSONEditor/JSONEditor'
import { PropertyPreviewPopupContainer } from '../styles/objectViewPages.styles'
import { Client } from '../types/Client'
import { Popup } from './Popup'
import { Entity } from '../types/Entity'

interface VersionPreviewModalProps {
  fetchedData: Client | Entity
  versionPreviewNumber: number
  isVersionPreviewOpen: boolean
  setIsVersionPreviewOpen: (isOpen: boolean) => void
}

export const VersionPreviewModal: FC<VersionPreviewModalProps> = ({
  fetchedData,
  versionPreviewNumber,
  isVersionPreviewOpen,
  setIsVersionPreviewOpen,
}) => {
  const theme = useTheme()

  return (
    <Popup
      visibility={isVersionPreviewOpen}
      handleVisibility={setIsVersionPreviewOpen}
      {...theme.popup}
      width="80%"
      height="80%"
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsVersionPreviewOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      <PropertyPreviewPopupContainer>
        <JSONEditor
          value={JSON.stringify(
            fetchedData?.versions?.find((version) => version?.version === versionPreviewNumber),
            undefined,
            4,
          )}
        />
      </PropertyPreviewPopupContainer>
    </Popup>
  )
}
