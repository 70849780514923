import { toast } from 'react-toastify'
import { axiosInstance } from '../methods/axiosConfig'
import { STAGE } from '../constants/envs'
import { Client, ClientListItem } from '../types/Client'
import { Entity, EntityListItem } from '../types/Entity'

export const getClientList = async (id?: string) => {
  try {
    return await axiosInstance.get<ClientListItem[]>(`/configurations`, {
      params: {
        type: 'Client',
        clientId: id || '',
      },
    })
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const getEntityList = async (id?: string) => {
  try {
    return await axiosInstance.get<EntityListItem[]>(`/configurations`, {
      params: {
        type: 'Entity',
        clientId: id || '',
      },
    })
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const getClient = async (clientId: string) => {
  try {
    if (STAGE === 'local') {
      return await axiosInstance.get<Client>(`/clientMock`)
    } else {
      return await axiosInstance.get<Client>(`/configuration`, {
        params: {
          type: 'Client',
          clientId,
        },
      })
    }
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putConfiguration = async (configuration: any) => {
  try {
    return await axiosInstance.put<Client>(`/configuration`, configuration)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const getEntity = async (clientId: string) => {
  try {
    if (STAGE === 'local') {
      return await axiosInstance.get<Entity>(`/entityMock`)
    } else {
      return await axiosInstance.get<Entity>(`/configuration`, {
        params: {
          type: 'Entity',
          clientId,
        },
      })
    }
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}
