import { Icon, IconProps } from './Icon.styles'

export const BackArrowIcon = ({ size, color, margin }: IconProps) => {
  return (
    <Icon size={size} margin={margin}>
      <svg
        style={{ transform: 'rotate(180deg)' }}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill={color ? color : 'currentColor'}
          d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"
        />
      </svg>
    </Icon>
  )
}
