export const CancelIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M16 3C8.832 3 3 8.832 3 16s5.832 13 13 13 13-5.832 13-13S23.168 3 16 3zm0 2c6.087 0 11 4.913 11 11s-4.913 11-11 11S5 22.087 5 16 9.913 5 16 5zm-3.78 5.78l-1.44 1.44L14.564 16l-3.782 3.78 1.44 1.44L16 17.437l3.78 3.78 1.44-1.437L17.437 16l3.78-3.78-1.437-1.44L16 14.564l-3.78-3.782z"
      />
    </svg>
  )
}
