import styled from 'styled-components'

export const PageTitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary2};
  font-size: 30px;
  line-height: 1.22;

  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 32px;
  }
`

export const PageContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary2};
  background-color: ${({ theme }) => theme.colors.quaternary};
  font-family: ${({ theme }) => theme.fonts.familyVariant};
  border-radius: 16px;
  padding: 32px;
`

export const LoaderWrapper = styled.div`
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`
