import { FC } from 'react'
import { CloseIcon } from '../../components/Icons/CloseIcon'
import { JSONEditor } from '../../components/JSONEditor/JSONEditor'
import {
  PropertyPreviewPopupContainer,
  PropertyPreviewPopupValue,
  SectionTitle,
} from '../../styles/objectViewPages.styles'
import { Popup } from '../../components/Popup'
import { Client } from '../../types/Client'
import { useTheme } from 'styled-components'
import { useLocation } from 'wouter'

interface RefPreviewModalProps {
  fetchedClient: Client
  propertyPreviewName: string
  isObjectPreviewOpen: boolean
  setIsObjectPreviewOpen: (isOpen: boolean) => void
}

export const RefPreviewModal: FC<RefPreviewModalProps> = ({
  fetchedClient,
  propertyPreviewName,
  isObjectPreviewOpen,
  setIsObjectPreviewOpen,
}) => {
  const theme = useTheme()
  //eslint-disable-next-line
  const [location, setLocation] = useLocation()

  const navigate = ({ type, clientId }: { type?: string; clientId?: string }) => {
    setLocation(`/configurations/${type === 'Entity' ? 'entities' : 'clients'}/${clientId}?returnUrl=${location}`)
  }

  const renderPreviewModalBody = () => {
    try {
      const previewValue = fetchedClient?.referenceMapping.find(
        (item) => item.unresolvedPath === propertyPreviewName,
      )?.resolvedValue

      if (typeof previewValue === 'object' && !Array.isArray(previewValue) && previewValue !== null) {
        return (
          <>
            {previewValue?.clientId ? (
              <SectionTitle
                $clickable
                onClick={() => {
                  navigate(previewValue)
                }}
              >
                {previewValue?.clientId}
              </SectionTitle>
            ) : (
              <SectionTitle>{propertyPreviewName}</SectionTitle>
            )}

            <JSONEditor value={JSON.stringify(previewValue, undefined, 4)} />
          </>
        )
      }

      return (
        <>
          <SectionTitle>{propertyPreviewName}</SectionTitle>
          <PropertyPreviewPopupValue>{previewValue.toString()}</PropertyPreviewPopupValue>
        </>
      )
    } catch (error) {
      return <>something went wrong</>
    }
  }

  return (
    <Popup
      visibility={isObjectPreviewOpen}
      handleVisibility={setIsObjectPreviewOpen}
      {...theme.popup}
      width="80%"
      height="80%"
      closeIcon={
        <CloseIcon
          color="currentColor"
          bgColor="transparent"
          onClick={() => {
            setIsObjectPreviewOpen(false)
          }}
        />
      }
      clickOutsideEnabled
    >
      <PropertyPreviewPopupContainer>{renderPreviewModalBody()}</PropertyPreviewPopupContainer>
    </Popup>
  )
}
