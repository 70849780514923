import { FC, useEffect, useState } from 'react'
import { LoadingSpinner } from 'authflow-ui-engine'
import { JSONEditor } from './JSONEditor/JSONEditor'
import { JSONEditorLoadingOverlay, JSONViewerWrapper } from '../styles/objectViewPages.styles'

import { ObjectIcon } from './Icons/ObjectIcon'
import { TooltipItem } from './JSONEditor/JSONEditor.styles'
import { Client } from '../types/Client'
import { putConfiguration } from '../communication/Configurations'
import { UploadIcon } from './Icons/JSONEditorToolbox/Upload'
import { CancelIcon } from './Icons/JSONEditorToolbox/Cancel'
import { EditIcon } from './Icons/JSONEditorToolbox/Edit'
import { useTheme } from 'styled-components'
import { Entity } from '../types/Entity'
import { checkIfUserCanEditResource } from '../communication/Permissions'

interface ObjectEditProps {
  fetchedData: Client | Entity
  JSONWrapperHeight: string

  // needed if Client
  setPropertyPreviewName?: (name: string) => void
  setIsObjectPreviewOpen?: (isOpen: boolean) => void
  refetchData: () => void
}

export const ObjectEdit: FC<ObjectEditProps> = ({
  fetchedData,
  JSONWrapperHeight,
  setPropertyPreviewName,
  setIsObjectPreviewOpen,
  refetchData,
}) => {
  const theme = useTheme()
  const isClient = fetchedData?.current?.type === 'Client'

  const [isLoading, setIsLoading] = useState(false)
  const [isUserAllowedToEdit, setIsUserAllowedToEdit] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editorValue, setEditorValue] = useState('')

  const [JSONViewerKey, setJSONViewerKey] = useState(0)

  const onChange = (e, val) => {
    if (val) {
      setEditorValue(val)
    }
  }

  const JSONPropIconOnclick = (propertyName?: string) => {
    if (!propertyName) {
      setPropertyPreviewName('current')
    } else {
      setPropertyPreviewName('current/' + propertyName)
    }
    setIsObjectPreviewOpen(true)
  }

  const uploadNewValue = async () => {
    setIsLoading(true)

    await putConfiguration(JSON.parse(editorValue))

    await refetchData()

    resetEditor()

    setIsLoading(false)
  }

  const renderEditIconState = () => {
    if (!isUserAllowedToEdit) {
      return []
    }

    if (isEditing) {
      return [
        <TooltipItem key="preview-current-ref-btn" onClick={uploadNewValue} title="Save & upload">
          <UploadIcon />
        </TooltipItem>,
        <TooltipItem
          key="cancel-edit-btn"
          onClick={() => {
            setIsEditing(false)
          }}
          title="Cancel editing"
        >
          <CancelIcon />
        </TooltipItem>,
      ]
    }

    return [
      <TooltipItem
        key="edit-btn"
        onClick={() => {
          setIsEditing(true)
        }}
        title="Edit"
      >
        <EditIcon />
      </TooltipItem>,
      isClient ? (
        <TooltipItem
          key="preview-current-ref-btn"
          onClick={() => {
            JSONPropIconOnclick()
          }}
        >
          <ObjectIcon size={24} />
        </TooltipItem>
      ) : null,
    ]
  }

  const resetEditor = () => {
    setEditorValue(JSON.stringify(fetchedData?.current, null, 4))
    setJSONViewerKey(JSONViewerKey + 1)
  }

  useEffect(() => {
    resetEditor()
  }, [fetchedData, isEditing])

  const checkEditPerms = async () => {
    setIsLoading(true)

    try {
      const { data } = await checkIfUserCanEditResource(fetchedData?.current?.clientId)
      setIsUserAllowedToEdit(!!data?.authorized)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkEditPerms()
  }, [fetchedData])

  return (
    <JSONViewerWrapper $maxHeight={JSONWrapperHeight}>
      <JSONEditor
        key={JSONViewerKey}
        value={editorValue}
        onChange={isEditing ? onChange : undefined}
        maxHeight="100%"
        tooltipAdditionalElements={[...renderEditIconState()]}
        widgetOnClick={isClient ? JSONPropIconOnclick : undefined}
        widgetPropNameList={isClient ? fetchedData?.referenceMapping?.map((item) => item.unresolvedPath) : undefined}
      />
      {isLoading && (
        <JSONEditorLoadingOverlay>
          <LoadingSpinner {...theme.loadingSpinner} />
        </JSONEditorLoadingOverlay>
      )}
    </JSONViewerWrapper>
  )
}
