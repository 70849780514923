import { styled } from 'styled-components'

const UploadSvg = styled.svg`
  width: 32px;
  height: 32px;
  padding: 2px;

  svg {
    width: 28px;
    height: 28px;
  }
`

export const UploadIcon = () => {
  return (
    <UploadSvg>
      <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="currentColor"
          d="M512 1536h768v-384h-768v384zm896 0h128v-896q0-14-10-38.5t-20-34.5l-281-281q-10-10-34-20t-39-10v416q0 40-28 68t-68 28h-576q-40 0-68-28t-28-68v-416h-128v1280h128v-416q0-40 28-68t68-28h832q40 0 68 28t28 68v416zm-384-928v-320q0-13-9.5-22.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 22.5v320q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5zm640 32v928q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1344q0-40 28-68t68-28h928q40 0 88 20t76 48l280 280q28 28 48 76t20 88z"
        />
      </svg>
    </UploadSvg>
  )
}
