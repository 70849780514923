import styled from 'styled-components'

export const ShortcutLogo = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9995 3.01403e-05L0 29.9995L29.9995 59.999L59.999 29.9995L29.9995 3.01403e-05Z"
        fill="url(#paint0_linear_2897_1966)"
      />
      <path
        d="M60.0005 29.9989L30.001 59.9984L60.0005 89.9979L90 59.9984L60.0005 29.9989Z"
        fill="url(#paint1_linear_2897_1966)"
      />
      <path d="M30.0001 0.000244141L59.999 29.9991L89.9991 0.000244141H30.0001Z" fill="url(#paint2_linear_2897_1966)" />
      <path d="M30.0001 59.9993L0 89.9982H59.999L30.0001 59.9993Z" fill="url(#paint3_linear_2897_1966)" />
      <path d="M30.0001 59.9993L0 89.9982H59.999L30.0001 59.9993Z" fill="url(#paint4_linear_2897_1966)" />
      <defs>
        <linearGradient
          id="paint0_linear_2897_1966"
          x1="44.783"
          y1="44.6362"
          x2="16.472"
          y2="16.6075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B95C9" />
          <stop offset="1" stopColor="#59B1E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2897_1966"
          x1="47.1291"
          y1="46.465"
          x2="74.5906"
          y2="75.3419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59B1E3" />
          <stop offset="1" stopColor="#9BD0F0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2897_1966"
          x1="84.9355"
          y1="-6.35618"
          x2="28.7927"
          y2="23.6662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59B1E3" />
          <stop offset="1" stopColor="#9BD0F0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2897_1966"
          x1="61.7661"
          y1="64.8214"
          x2="5.63199"
          y2="98.8932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59B1E3" />
          <stop offset="1" stopColor="#9BD0F0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2897_1966"
          x1="44.8033"
          y1="74.9399"
          x2="28.3829"
          y2="91.6439"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B95C9" />
          <stop offset="1" stopColor="#9BD0F0" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const OnboardingDashboardLogo = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_373_59)">
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="#2A0760"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.022 18.0376C31.4623 18.0376 18.038 31.4618 18.038 48.0215C18.038 64.5812 31.4623 78.0055 48.022 78.0055C64.5817 78.0055 78.006 64.5812 78.006 48.0215C78.006 31.4618 64.5817 18.0376 48.022 18.0376ZM13.1626 48.0215C13.1626 28.7692 28.7697 13.1621 48.022 13.1621C67.2743 13.1621 82.8814 28.7692 82.8814 48.0215C82.8814 67.2738 67.2743 82.8809 48.022 82.8809C28.7697 82.8809 13.1626 67.2738 13.1626 48.0215Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.5762 53.7705C63.748 59.3922 59.3627 63.8179 53.7425 65.656L53.7413 65.6564C44.0388 68.8239 33.6229 63.5014 30.4678 53.7683L35.091 52.2561C37.4138 59.4215 45.0845 63.3462 52.2372 61.0119C56.3737 59.659 59.6059 56.4004 60.9543 52.2539L65.5762 53.7705Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.0027 36.5237C35.798 37.7284 35.798 39.6817 37.0027 40.8865C38.2075 42.0913 40.1608 42.0913 41.3656 40.8865C42.5703 39.6817 42.5703 37.7284 41.3656 36.5237C40.1608 35.3189 38.2075 35.3189 37.0027 36.5237ZM33.5584 44.3308C30.4514 41.2238 30.4514 36.1863 33.5584 33.0793C36.6654 29.9723 41.7029 29.9723 44.8099 33.0793C47.9169 36.1863 47.9169 41.2238 44.8099 44.3308C41.7029 47.4379 36.6654 47.4379 33.5584 44.3308Z"
          fill="white"
        />
        <path
          d="M57.3934 37.1087C56.1081 39.2448 54.1884 40.2642 51.4716 40.4261L50.5117 35.6523C54.4487 35.4905 57.0029 33.4839 57.8651 30.7168H62.339V46.1545H57.3771V37.1087H57.3934Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_373_59">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const OpsGenieSvg = styled.svg`
  transform: scale(1.5);
  enable-background: new 0 0 1032 1024;

  .st0 {
    fill: url(#SVGID_1_);
  }
  .st1 {
    fill: url(#SVGID_2_);
  }
  .st2 {
    fill: #2585ff;
  }

  .stop1 {
    stop-color: #2a83ff;
  }

  .stop2 {
    stop-color: #0e64df;
  }
`

export const OpsGenieLogo = () => {
  return (
    <OpsGenieSvg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 1032 1024"
      xmlSpace="preserve"
    >
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="515.9559"
        y1="747.9766"
        x2="515.9559"
        y2="458.1248"
        gradientTransform="matrix(1 0 0 -1 0 1024)"
      >
        <stop offset="0" className="stop1" />
        <stop offset="0.824" className="stop2" />
      </linearGradient>
      <circle className="st0" cx="516" cy="370.3" r="141.1" />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="429.1548"
        y1="466.3492"
        x2="526.1769"
        y2="263.0984"
        gradientTransform="matrix(1 0 0 -1 0 1024)"
      >
        <stop offset="0" className="stop1" />
        <stop offset="0.6226" className="stop2" />
      </linearGradient>
      <path
        className="st1"
        d="M504,791.3c-92-58.6-168.9-138.8-223.5-233.6c-4.5-7.9-1.3-18,6.9-22l106.9-52.4c7.5-3.6,16.4-0.9,20.6,6.3  c53.9,90.2,134,163,229.8,207.7c-35.2,35.5-74.3,67-116.6,93.9C520.7,796,511.3,796,504,791.3z"
      />
      <path
        className="st2"
        d="M528,791.3c92.1-58.6,169-138.8,223.6-233.6c4.5-7.9,1.3-18-6.9-22l-107-52.4c-7.5-3.6-16.4-0.9-20.6,6.3  c-53.9,90.2-134,163-229.8,207.7c35.2,35.5,74.3,67,116.6,93.9C511.3,796,520.7,796,528,791.3z"
      />
    </OpsGenieSvg>
  )
}

export const ZendeskLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="14 0 50 36"
      fill="#03353d"
      role="img"
      aria-label="Zendesk"
      className="sc-ae7eebdd-0 dVOxfj"
    >
      <path d="M37.5 8.8v24.4H17.4L37.5 8.8zm0-8.8c0 5.6-4.5 10.1-10.1 10.1s-10-4.5-10-10.1h20.1zm3.3 33.1c0-5.6 4.5-10.1 10.1-10.1S61 27.5 61 33.1H40.8zm0-8.7V0H61L40.8 24.4z"></path>
    </svg>
  )
}

export const ConfluenceLogo = () => {
  return (
    <svg
      width="256px"
      height="246px"
      viewBox="0 0 256 246"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
    >
      <defs>
        <linearGradient x1="99.140087%" y1="112.708084%" x2="33.8589812%" y2="37.7549606%" id="linearGradient-1">
          <stop stopColor="#0052CC" offset="18%"></stop>
          <stop stopColor="#2684FF" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0.92569163%" y1="-12.5823074%" x2="66.1800713%" y2="62.3057471%" id="linearGradient-2">
          <stop stopColor="#0052CC" offset="18%"></stop>
          <stop stopColor="#2684FF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g>
        <path
          d="M9.26054484,187.329971 C6.61939782,191.637072 3.65318655,196.634935 1.13393863,200.616972 C-1.12098385,204.42751 0.0895487945,209.341911 3.85635171,211.669157 L56.6792921,244.175582 C58.5334859,245.320393 60.7697695,245.67257 62.8860683,245.153045 C65.0023672,244.633521 66.8213536,243.285826 67.9346417,241.412536 C70.0475593,237.877462 72.7699724,233.285929 75.7361837,228.369333 C96.6621947,193.831256 117.710105,198.057091 155.661356,216.179423 L208.037333,241.087471 C210.020997,242.031639 212.302415,242.132457 214.361632,241.366949 C216.420848,240.601441 218.082405,239.034833 218.967618,237.024168 L244.119464,180.137925 C245.896483,176.075046 244.088336,171.3377 240.056161,169.492071 C229.003977,164.291043 207.021507,153.92962 187.233221,144.380857 C116.044151,109.802148 55.5415672,112.036965 9.26054484,187.329971 Z"
          fill="url(#linearGradient-1)"
        ></path>
        <path
          d="M246.11505,58.2319428 C248.756197,53.9248415 251.722408,48.9269787 254.241656,44.9449416 C256.496579,41.1344037 255.286046,36.2200025 251.519243,33.8927572 L198.696303,1.38633231 C196.82698,0.127283893 194.518741,-0.298915762 192.323058,0.209558312 C190.127374,0.718032386 188.241461,2.11550922 187.115889,4.06811236 C185.002971,7.60318607 182.280558,12.1947186 179.314347,17.1113153 C158.388336,51.6493918 137.340426,47.4235565 99.3891748,29.3012247 L47.1757299,4.5150757 C45.1920661,3.57090828 42.9106475,3.47008979 40.8514312,4.2355977 C38.7922149,5.00110562 37.1306578,6.56771434 36.2454445,8.57837881 L11.0935983,65.4646223 C9.31657942,69.5275012 11.1247267,74.2648471 15.1569014,76.1104765 C26.2090859,81.3115044 48.1915557,91.6729274 67.9798418,101.22169 C139.331444,135.759766 199.834028,133.443683 246.11505,58.2319428 Z"
          fill="url(#linearGradient-2)"
        ></path>
      </g>
    </svg>
  )
}

const SalesforceIcon = styled.svg`
  .cls-1,
  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    fill: #0d9dda;
  }

  .cls-2 {
    fill-rule: evenodd;
  }
`

export const SalesforceLogo = () => {
  return (
    <SalesforceIcon xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 92 64">
      <path
        className="cls-3"
        d="m38.33,6.98c2.95-3.07,7.05-4.98,11.59-4.98,6.04,0,11.3,3.37,14.11,8.36,2.44-1.09,5.14-1.7,7.97-1.7,10.89,0,19.71,8.9,19.71,19.89s-8.83,19.89-19.71,19.89c-1.33,0-2.63-.13-3.88-.39-2.47,4.4-7.18,7.38-12.58,7.38-2.26,0-4.4-.52-6.3-1.45-2.5,5.89-8.34,10.02-15.13,10.02s-13.11-4.48-15.43-10.76c-1.01.21-2.06.33-3.14.33-8.43,0-15.26-6.9-15.26-15.42,0-5.71,3.07-10.69,7.63-13.36-.94-2.16-1.46-4.55-1.46-7.05C6.45,7.94,14.4,0,24.21,0,29.97,0,35.09,2.74,38.33,6.98Z"
      />
      <g>
        <path
          className="cls-1"
          d="m13.53,33.19c-.06.15.02.18.04.21.17.12.35.21.52.32.93.49,1.81.64,2.73.64,1.88,0,3.04-1,3.04-2.6v-.03c0-1.48-1.32-2.02-2.55-2.41l-.16-.05c-.93-.3-1.73-.56-1.73-1.18v-.03c0-.52.47-.91,1.2-.91.81,0,1.77.27,2.38.61,0,0,.18.12.25-.06.04-.1.35-.94.38-1.03.04-.1-.03-.17-.09-.21-.71-.43-1.68-.72-2.69-.72h-.19c-1.72,0-2.92,1.04-2.92,2.52v.03c0,1.57,1.32,2.07,2.56,2.43l.2.06c.9.28,1.68.52,1.68,1.15v.03c0,.58-.51,1.01-1.32,1.01-.32,0-1.33,0-2.42-.7-.13-.08-.21-.13-.31-.19-.05-.03-.19-.09-.25.08l-.37,1.03Z"
        />
        <path
          className="cls-1"
          d="m40.98,33.19c-.06.15.02.18.04.21.17.12.35.21.52.32.93.49,1.81.64,2.73.64,1.88,0,3.04-1,3.04-2.6v-.03c0-1.48-1.31-2.02-2.55-2.41l-.16-.05c-.93-.3-1.73-.56-1.73-1.18v-.03c0-.52.47-.91,1.2-.91.81,0,1.77.27,2.38.61,0,0,.18.12.25-.06.04-.1.35-.94.38-1.03.04-.1-.03-.17-.09-.21-.71-.43-1.68-.72-2.69-.72h-.19c-1.72,0-2.92,1.04-2.92,2.52v.03c0,1.57,1.32,2.07,2.56,2.43l.2.06c.9.28,1.68.52,1.68,1.15v.03c0,.58-.51,1.01-1.32,1.01-.32,0-1.33,0-2.42-.7-.13-.08-.21-.13-.31-.19-.03-.02-.19-.08-.25.08l-.37,1.03Z"
        />
        <path
          className="cls-1"
          d="m61.29,28.36c-.15-.52-.39-.98-.71-1.36-.32-.38-.72-.69-1.2-.91-.48-.22-1.04-.34-1.68-.34s-1.2.11-1.68.34c-.48.22-.88.53-1.2.91-.32.38-.56.84-.71,1.36-.15.52-.23,1.08-.23,1.68s.08,1.17.23,1.68c.15.52.39.98.71,1.36.32.38.73.69,1.2.91.48.22,1.04.33,1.68.33s1.2-.11,1.68-.33c.48-.22.88-.52,1.2-.91.32-.38.56-.84.71-1.36.15-.52.23-1.08.23-1.68s-.08-1.17-.23-1.68m-1.57,1.68c0,.91-.17,1.62-.5,2.13-.33.5-.83.74-1.52.74s-1.19-.24-1.52-.74c-.33-.51-.5-1.22-.5-2.13s.17-1.62.49-2.12c.33-.5.82-.74,1.52-.74s1.19.24,1.52.74c.33.5.5,1.21.5,2.12"
        />
        <path
          className="cls-2"
          d="m74.15,32.67c-.05-.15-.2-.09-.2-.09-.23.09-.47.17-.73.21-.26.04-.55.06-.86.06-.76,0-1.36-.23-1.79-.67-.43-.45-.68-1.17-.67-2.14,0-.89.22-1.55.6-2.06.38-.51.96-.76,1.74-.76.65,0,1.14.07,1.66.24,0,0,.12.05.18-.11.14-.38.24-.65.39-1.07.04-.12-.06-.17-.1-.18-.2-.08-.68-.21-1.04-.26-.34-.05-.73-.08-1.17-.08-.66,0-1.24.11-1.75.34-.5.22-.92.53-1.26.91-.34.38-.59.84-.76,1.36-.17.52-.26,1.08-.26,1.69,0,1.3.35,2.35,1.04,3.12.69.77,1.73,1.16,3.09,1.16.8,0,1.62-.16,2.22-.4,0,0,.11-.05.06-.19l-.38-1.06Z"
        />
        <path
          className="cls-2"
          d="m82.16,28.07c-.13-.5-.46-1.01-.68-1.24-.34-.37-.67-.62-1.01-.77-.43-.19-.95-.31-1.52-.31-.66,0-1.26.11-1.75.34-.49.23-.9.54-1.22.93-.32.39-.56.85-.72,1.38-.15.52-.23,1.09-.23,1.69s.08,1.18.24,1.69c.16.52.42.97.77,1.35.35.38.8.68,1.33.88.53.21,1.18.31,1.92.31,1.53,0,2.34-.35,2.67-.53.06-.03.11-.09.05-.25l-.35-.97c-.05-.14-.2-.09-.2-.09-.38.14-.92.39-2.18.39-.82,0-1.43-.24-1.81-.62-.39-.39-.58-.96-.62-1.76h5.3s.14,0,.15-.13c0-.06.18-1.09-.16-2.28Zm-5.27,1.11c.07-.5.21-.92.43-1.25.32-.5.82-.77,1.52-.77s1.15.27,1.48.77c.22.33.31.76.35,1.25h-3.78Z"
        />
        <path
          className="cls-2"
          d="m39.72,28.07c-.13-.5-.46-1.01-.68-1.24-.34-.37-.67-.62-1.01-.77-.43-.19-.95-.31-1.52-.31-.66,0-1.26.11-1.75.34-.49.23-.9.54-1.22.93-.32.39-.56.85-.72,1.38-.15.52-.23,1.09-.23,1.69s.08,1.18.24,1.69c.16.52.42.97.77,1.35.35.38.8.68,1.33.88.53.21,1.18.31,1.92.31,1.53,0,2.34-.35,2.67-.53.06-.03.11-.09.04-.25l-.35-.97c-.05-.14-.2-.09-.2-.09-.38.14-.92.39-2.18.39-.82,0-1.43-.24-1.81-.62-.39-.39-.58-.96-.62-1.76h5.3s.14,0,.15-.13c0-.06.18-1.09-.16-2.28Zm-5.27,1.11c.07-.5.21-.92.43-1.25.33-.5.82-.77,1.52-.77s1.15.27,1.49.77c.22.33.31.76.35,1.25h-3.78Z"
        />
        <path
          className="cls-1"
          d="m25.1,28.92c-.22-.02-.49-.02-.83-.02-.45,0-.89.06-1.31.17-.41.11-.79.28-1.11.51-.32.23-.58.52-.77.87-.19.35-.28.76-.28,1.22s.08.88.24,1.21c.16.33.39.61.69.83.29.21.66.37,1.08.47.42.09.89.14,1.4.14.54,0,1.09-.05,1.61-.13.52-.09,1.16-.22,1.33-.26.18-.04.37-.09.37-.09.13-.03.12-.17.12-.17v-4.85c0-1.06-.29-1.85-.85-2.34-.56-.49-1.37-.73-2.43-.73-.4,0-1.04.05-1.42.13,0,0-1.16.22-1.63.6,0,0-.1.06-.05.21l.38,1.01c.05.13.17.09.17.09,0,0,.04-.02.09-.04,1.02-.55,2.31-.54,2.31-.54.57,0,1.01.12,1.31.34.29.22.44.56.44,1.26v.22c-.45-.07-.87-.1-.87-.1Zm-2.11,3.72c-.21-.17-.24-.21-.31-.31-.1-.16-.16-.39-.16-.69,0-.47.15-.8.47-1.03,0,0,.46-.4,1.54-.38.76,0,1.44.12,1.44.12v2.41h0s-.67.14-1.43.19c-1.08.06-1.56-.31-1.55-.31Z"
        />
        <path
          className="cls-2"
          d="m67.84,26.19c.04-.12-.04-.18-.08-.19-.09-.03-.54-.13-.89-.15-.67-.04-1.04.07-1.37.22-.33.15-.69.39-.9.66v-.65c0-.09-.06-.16-.15-.16h-1.36c-.09,0-.15.07-.15.16v7.91c0,.09.07.16.16.16h1.39c.09,0,.16-.07.16-.16v-3.95c0-.53.06-1.06.18-1.39.11-.33.27-.59.46-.78.19-.19.41-.32.65-.39.25-.08.52-.1.71-.1.28,0,.58.07.58.07.1.01.16-.05.19-.14.09-.24.35-.97.4-1.11Z"
        />
        <path
          className="cls-2"
          d="m54.77,22.53c-.17-.05-.32-.09-.52-.12-.2-.04-.45-.06-.72-.06-.96,0-1.71.27-2.24.81-.53.53-.89,1.34-1.06,2.4l-.06.36h-1.2s-.15,0-.18.15l-.2,1.1c-.01.11.03.17.17.17h1.17l-1.19,6.63c-.09.53-.2.97-.32,1.31-.12.33-.23.57-.37.75-.14.17-.26.3-.48.37-.18.06-.39.09-.62.09-.13,0-.3-.02-.43-.05-.13-.02-.19-.05-.29-.09,0,0-.14-.05-.19.09-.04.11-.36.97-.39,1.08-.04.1.02.19.08.21.16.06.27.09.48.14.29.07.54.07.78.07.49,0,.93-.07,1.3-.2.37-.13.69-.37.98-.68.31-.34.5-.7.69-1.19.18-.48.34-1.08.47-1.78l1.19-6.76h1.75s.15,0,.18-.15l.2-1.1c.01-.11-.03-.17-.17-.17h-1.69s.09-.63.28-1.2c.08-.24.24-.43.37-.57.13-.13.28-.22.44-.28.17-.05.36-.08.57-.08.16,0,.32.02.44.04.16.03.23.05.27.07.17.05.2,0,.23-.08l.41-1.11c.04-.12-.06-.17-.1-.18Z"
        />
        <path
          className="cls-2"
          d="m31.1,33.99c0,.09-.06.16-.15.16h-1.41c-.09,0-.15-.07-.15-.16v-11.31c0-.09.06-.16.15-.16h1.41c.09,0,.15.07.15.16v11.31Z"
        />
      </g>
    </SalesforceIcon>
  )
}

export const AzureLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 96 96">
      <defs>
        <linearGradient
          id="e399c19f-b68f-429d-b176-18c2117ff73c"
          x1="-1032.172"
          x2="-1059.213"
          y1="145.312"
          y2="65.426"
          gradientTransform="matrix(1 0 0 -1 1075 158)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#114a8b" />
          <stop offset="1" stopColor="#0669bc" />
        </linearGradient>
        <linearGradient
          id="ac2a6fc2-ca48-4327-9a3c-d4dcc3256e15"
          x1="-1023.725"
          x2="-1029.98"
          y1="108.083"
          y2="105.968"
          gradientTransform="matrix(1 0 0 -1 1075 158)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopOpacity=".3" />
          <stop offset=".071" stopOpacity=".2" />
          <stop offset=".321" stopOpacity=".1" />
          <stop offset=".623" stopOpacity=".05" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="a7fee970-a784-4bb1-af8d-63d18e5f7db9"
          x1="-1027.165"
          x2="-997.482"
          y1="147.642"
          y2="68.561"
          gradientTransform="matrix(1 0 0 -1 1075 158)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#3ccbf4" />
          <stop offset="1" stopColor="#2892df" />
        </linearGradient>
      </defs>
      <path
        fill="url(#e399c19f-b68f-429d-b176-18c2117ff73c)"
        d="M33.338 6.544h26.038l-27.03 80.087a4.152 4.152 0 0 1-3.933 2.824H8.149a4.145 4.145 0 0 1-3.928-5.47L29.404 9.368a4.152 4.152 0 0 1 3.934-2.825z"
      />
      <path
        fill="#0078d4"
        d="M71.175 60.261h-41.29a1.911 1.911 0 0 0-1.305 3.309l26.532 24.764a4.171 4.171 0 0 0 2.846 1.121h23.38z"
      />
      <path
        fill="url(#ac2a6fc2-ca48-4327-9a3c-d4dcc3256e15)"
        d="M33.338 6.544a4.118 4.118 0 0 0-3.943 2.879L4.252 83.917a4.14 4.14 0 0 0 3.908 5.538h20.787a4.443 4.443 0 0 0 3.41-2.9l5.014-14.777 17.91 16.705a4.237 4.237 0 0 0 2.666.972H81.24L71.024 60.261l-29.781.007L59.47 6.544z"
      />
      <path
        fill="url(#a7fee970-a784-4bb1-af8d-63d18e5f7db9)"
        d="M66.595 9.364a4.145 4.145 0 0 0-3.928-2.82H33.648a4.146 4.146 0 0 1 3.928 2.82l25.184 74.62a4.146 4.146 0 0 1-3.928 5.472h29.02a4.146 4.146 0 0 0 3.927-5.472z"
      />
    </svg>
  )
}

const GitlabSvg = styled.svg`
  .cls-1 {
    fill: #e24329;
  }
  .cls-2 {
    fill: #fc6d26;
  }
  .cls-3 {
    fill: #fca326;
  }
`

export const GitlabLogo = () => {
  return (
    <GitlabSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 380">
      <g id="LOGO">
        <path
          className="cls-1"
          d="M282.83,170.73l-.27-.69-26.14-68.22a6.81,6.81,0,0,0-2.69-3.24,7,7,0,0,0-8,.43,7,7,0,0,0-2.32,3.52l-17.65,54H154.29l-17.65-54A6.86,6.86,0,0,0,134.32,99a7,7,0,0,0-8-.43,6.87,6.87,0,0,0-2.69,3.24L97.44,170l-.26.69a48.54,48.54,0,0,0,16.1,56.1l.09.07.24.17,39.82,29.82,19.7,14.91,12,9.06a8.07,8.07,0,0,0,9.76,0l12-9.06,19.7-14.91,40.06-30,.1-.08A48.56,48.56,0,0,0,282.83,170.73Z"
        />
        <path
          className="cls-2"
          d="M282.83,170.73l-.27-.69a88.3,88.3,0,0,0-35.15,15.8L190,229.25c19.55,14.79,36.57,27.64,36.57,27.64l40.06-30,.1-.08A48.56,48.56,0,0,0,282.83,170.73Z"
        />
        <path
          className="cls-3"
          d="M153.43,256.89l19.7,14.91,12,9.06a8.07,8.07,0,0,0,9.76,0l12-9.06,19.7-14.91S209.55,244,190,229.25C170.45,244,153.43,256.89,153.43,256.89Z"
        />
        <path
          className="cls-2"
          d="M132.58,185.84A88.19,88.19,0,0,0,97.44,170l-.26.69a48.54,48.54,0,0,0,16.1,56.1l.09.07.24.17,39.82,29.82s17-12.85,36.57-27.64Z"
        />
      </g>
    </GitlabSvg>
  )
}
