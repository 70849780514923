import 'mobx-react-lite'
import React, { createContext, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'

import { lightTheme } from '../themes/light.theme'
import { darkTheme } from '../themes/dark.theme'

import { rootStore } from '../store/Root.store'
import FontStyles from '../styles/fonts.styles'
import { ContentRouter, URLS_WITHOUT_AUTH } from './ContentRouter'
import { Toasts } from './Toast/Toast'

import AppleTouchIcon from '../assets/favicons/apple-touch-icon.png'
import Favicon32 from '../assets/favicons/favicon-32x32.png'
import Favicon16 from '../assets/favicons/favicon-16x16.png'
import SiteManifest from '../assets/favicons/site.webmanifest'
import Favicon from '../assets/favicons/favicon.ico'
import { observer } from 'mobx-react'
import { GlobalStyle } from '../styles/App.styles'
import { useLocation } from 'wouter'

export const StoreContext = createContext(rootStore)

const themes = {
  light: lightTheme,
  dark: darkTheme,
}

export const App: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { initialAuthCheck, isAuthenticated } = store.CommunicationState
  const { selectedTheme } = rootStore.AppState

  const [location, setLocation] = useLocation()

  useEffect(() => {
    store.AppState.initTheme()

    if (URLS_WITHOUT_AUTH.includes(location)) return
    store.CommunicationState.checkLogin()
  }, [])

  useEffect(() => {
    if (initialAuthCheck && !isAuthenticated) {
      setLocation('/login')
    } else if (initialAuthCheck && isAuthenticated) {
      store.CommunicationState.fetchAllDataForInputs()
    }
  }, [initialAuthCheck, isAuthenticated])

  useEffect(() => {
    store.AppState.setOpenedPopupCount(0)
  }, [location])

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet defaultTitle="Pinkhub">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
        <link rel="manifest" href={SiteManifest} />
        <link rel="shortcut icon" href={Favicon} />
        <meta name="theme-color" content="#ffffff" />

        <meta name="description" content="Onboarding Web" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Just+Me+Again+Down+Here&display=swap" rel="stylesheet" />
      </Helmet>
      <ThemeProvider theme={themes[selectedTheme]}>
        <FontStyles />
        <GlobalStyle />
        <div className="App">
          <Toasts />
          <ContentRouter />
        </div>
      </ThemeProvider>
    </StoreContext.Provider>
  )
})
