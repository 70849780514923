import styled from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  button {
    align-self: flex-end;
  }

  label {
    color: ${({ theme }) => theme.colors.secondary};
  }

  input,
  textarea {
    background-color: ${({ theme }) => theme.colors.quaternary};
  }
`

export const PeopleOnCallTitle = styled.h2`
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  color: ${({ theme }) => theme.colors.secondary};
`

export const PeopleOnCallContainer = styled.div`
  max-width: 600px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PeopleOnCallRow = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.secondary};
  gap: 8px;
`

export const Participants = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
