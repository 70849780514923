import { toast } from 'react-toastify'
import { axiosInstance } from '../methods/axiosConfig'
import { encodeString } from '../methods/encode'
import { GetPermissions } from '../types/Permissions'

interface PostCreatePermissionData {
  resourceType: 'Config' | 'Secret'
  resourceName: string
  operation: 'Write' | 'Use' | string
  allowedGroups?: string[]
}

interface DeletePermissionData {
  resourceType: string
  resourceName: string
  operation: string
}

interface GetListPermissionsForResourceData {
  resourceType: string
  resourceName: string
}

interface PutOrDeleteGroupsToPermissionData {
  resourceType: string
  resourceName: string
  operation: string
  allowedGroups: string[]
}

export const getPermissionsList = async () => {
  try {
    return await axiosInstance.get<GetPermissions>('/permissions')
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const createPermission = async (data: PostCreatePermissionData) => {
  try {
    return await axiosInstance.put('/permissions', data)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const deletePermission = async ({ resourceType, resourceName, operation }: DeletePermissionData) => {
  try {
    return await axiosInstance.delete(
      `/permissions/type/${encodeString(resourceType)}/name/${encodeString(resourceName)}/operation/${encodeString(operation)}`,
    )
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const listPermissionsForResource = async ({ resourceType, resourceName }: GetListPermissionsForResourceData) => {
  try {
    return await axiosInstance.get<GetPermissions>(
      `/permissions/type/${encodeString(resourceType)}/name/${encodeString(resourceName)}`,
    )
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const listPermissionsForGroup = async (groupId: string) => {
  try {
    return await axiosInstance.get<GetPermissions>(`/groups/${encodeString(groupId)}/permissions`)
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const addGroupsToPermission = async ({
  resourceType,
  resourceName,
  operation,
  allowedGroups,
}: PutOrDeleteGroupsToPermissionData) => {
  try {
    return await axiosInstance.put(
      `/permissions/type/${encodeString(resourceType)}/name/${encodeString(resourceName)}/operation/${encodeString(operation)}/allowed-groups`,
      { allowedGroups },
    )
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export const deleteGroupsFromPermission = async ({
  resourceType,
  resourceName,
  operation,
  allowedGroups,
}: PutOrDeleteGroupsToPermissionData) => {
  try {
    return await axiosInstance.delete(
      `/permissions/type/${encodeString(resourceType)}/name/${encodeString(resourceName)}/operation/${encodeString(operation)}/allowed-groups`,
      { data: { allowedGroups } },
    )
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}

export interface CheckIfUserCanEditResource {
  resourceType: string
  resourceName: string
  operation: string
  authorized: boolean
}

export const checkIfUserCanEditResource = async (resourceName: string) => {
  try {
    return await axiosInstance.get<CheckIfUserCanEditResource>(
      `/permissions/type/Config/name/${encodeString(resourceName)}/operation/Write/users/current`,
    )
  } catch (e) {
    console.log(e)
    toast.error('Request failed')
  }
}
