import { FC, useContext } from 'react'
import { Button, LoadingSpinner } from 'authflow-ui-engine'
import {
  PopupTitle,
  ConfirmationPopupWrapper,
  PopupButtonWrapper,
  PopupGroupDetails,
  PopupParagraph,
  LoaderWrapper,
} from '../GroupsTablePage.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { CloseIcon } from '../../../components/Icons/CloseIcon'
import { GroupListItem } from '../../../types/Group'
import { deleteGroup } from '../../../communication/Groups'
import { StoreContext } from '../../../components/App'
import { Popup } from '../../../components/Popup'

interface DeleteGroupModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  groupDataToDelete: GroupListItem | null
  setGroupDataToDelete: (value: GroupListItem | null) => void
}

export const DeleteGroupModal: FC<DeleteGroupModalProps> = observer(
  ({ isOpen, setIsOpen, groupDataToDelete, setGroupDataToDelete }) => {
    const theme = useTheme()
    const store = useContext(StoreContext)
    const { isGroupActionLoading } = store.CommunicationState

    const deleteGroupFc = async () => {
      store.CommunicationState.setGroupActionLoading(true)

      await deleteGroup(groupDataToDelete?.id)

      store.CommunicationState.setGroupActionLoading(false)
      setIsOpen(false)
    }

    return (
      <Popup
        width="500px"
        visibility={isOpen}
        handleVisibility={setIsOpen}
        {...theme.popup}
        closeIcon={
          <CloseIcon
            color="currentColor"
            bgColor="transparent"
            onClick={() => {
              setIsOpen(false)
            }}
          />
        }
        clickOutsideEnabled
      >
        {isGroupActionLoading ? (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        ) : (
          <ConfirmationPopupWrapper>
            <PopupTitle>Delete group</PopupTitle>
            <PopupParagraph>
              Are you sure you want to <span>delete</span> selected group?
            </PopupParagraph>
            <PopupGroupDetails>
              <label>Name</label>
              <span>{groupDataToDelete?.name}</span>
              <label>Group ID</label>
              <span>{groupDataToDelete?.id}</span>
            </PopupGroupDetails>
            <PopupButtonWrapper>
              <Button
                type="button"
                {...theme.buttons.commonButton}
                width="100px"
                height="40px"
                onClick={() => {
                  setGroupDataToDelete(null)
                  setIsOpen(false)
                }}
              >
                Cancel
              </Button>

              <Button type="button" {...theme.buttons.commonButton} width="100px" height="40px" onClick={deleteGroupFc}>
                Delete
              </Button>
            </PopupButtonWrapper>
          </ConfirmationPopupWrapper>
        )}
      </Popup>
    )
  },
)
