import { FC, useContext, useEffect, useRef, useState } from 'react'
import { LoadingSpinner } from 'authflow-ui-engine'
import {
  FlexCol,
  FlexRow,
  LoaderWrapper,
  PageContainer,
  PageTitle,
  PageTopContainer,
  SectionTitle,
  TagsContainer,
  TagsItem,
  TagsList,
  VersionItem,
  VersionList,
} from '../../styles/objectViewPages.styles'
import { Page, Spacer } from '../../styles/generic.styles'
import { useTheme } from 'styled-components'
import { observer } from 'mobx-react'
import { StoreContext } from '../../components/App'
import { useTimeout } from 'usehooks-ts'
import { getEntity } from '../../communication/Configurations'
import { Entity } from '../../types/Entity'
import { BackButton } from '../../components/BackButton'
import { VersionPreviewModal } from '../../components/VersionPreviewModal'
import { ObjectEdit } from '../../components/ClientObjectEdit'

interface SingleEntityPageProps {
  params: {
    id: string
  }
}

export const SingleEntityPage: FC<SingleEntityPageProps> = observer(({ params }) => {
  const theme = useTheme()
  const store = useContext(StoreContext)

  const [fetchedEntity, setFetchedEntity] = useState<Entity>({} as Entity)

  const [fetching, setFetching] = useState(true)

  const fetchEntity = async () => {
    setFetching(true)
    const { data } = await getEntity(params.id)
    setFetchedEntity(data)
    store.CommunicationState.setEntity(data)

    setFetching(false)
  }

  useEffect(() => {
    fetchEntity()
  }, [])

  const currentContainer = useRef<HTMLDivElement>(null)
  const [JSONWrapperHeight, setJSONWrapperHeight] = useState('50vh' as string)

  useTimeout(() => {
    if (currentContainer.current) {
      setJSONWrapperHeight(`calc(${currentContainer.current.clientHeight}px)`)
    }
  }, 200)

  const [isVersionPreviewOpen, setIsVersionPreviewOpen] = useState(false)
  const [versionPreviewNumber, setVersionPreviewNumber] = useState<number>()

  return (
    <Page>
      <PageTopContainer>
        <PageTitle>
          <BackButton backUrl="/configurations/entities" />
          {params.id}
        </PageTitle>
      </PageTopContainer>

      <Spacer size={20} />

      <PageContainer>
        {!fetching && fetchedEntity?.current?.clientId ? (
          <FlexCol>
            {!!fetchedEntity?.references?.length && (
              <>
                <FlexRow $height="unset">
                  <TagsContainer>
                    <SectionTitle>References:</SectionTitle>
                    <TagsList>
                      {fetchedEntity.references.map((reference, index) => (
                        <TagsItem key={index}>{reference}</TagsItem>
                      ))}
                    </TagsList>
                  </TagsContainer>
                </FlexRow>
                <Spacer size={16} />
              </>
            )}

            <FlexRow $height="100%">
              <FlexCol $maxHeight="100%" $width="70%" ref={currentContainer}>
                <ObjectEdit
                  fetchedData={fetchedEntity}
                  refetchData={fetchEntity}
                  JSONWrapperHeight={JSONWrapperHeight}
                />
              </FlexCol>

              <FlexCol $maxHeight="100%" $width="30%">
                <SectionTitle>Versions:</SectionTitle>
                <VersionList>
                  {fetchedEntity?.versions?.map((version, index) => (
                    <VersionItem
                      key={index}
                      onClick={() => {
                        setVersionPreviewNumber(version?.version)
                        setIsVersionPreviewOpen(true)
                      }}
                    >
                      {version.clientId}
                    </VersionItem>
                  ))}
                </VersionList>
              </FlexCol>
            </FlexRow>
          </FlexCol>
        ) : (
          <LoaderWrapper>
            <LoadingSpinner width="60px" {...theme.loadingSpinner} />
          </LoaderWrapper>
        )}
      </PageContainer>
      <Spacer size={30}></Spacer>

      <VersionPreviewModal
        fetchedData={fetchedEntity}
        versionPreviewNumber={versionPreviewNumber}
        isVersionPreviewOpen={isVersionPreviewOpen}
        setIsVersionPreviewOpen={setIsVersionPreviewOpen}
      />
    </Page>
  )
})
