import { LoadingSpinner } from 'authflow-ui-engine'
import { Redirect, Route, Switch, useLocation } from 'wouter'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import styled, { useTheme } from 'styled-components'

import { NotFoundPage } from '../pages/NotFoundPage'
import { ContentWrap, RouterContainer } from '../styles/generic.styles'
import { StoreContext } from './App'
import { Menu } from './Menu/Menu'
import { ClientsTablePage } from '../pages/ClientsTable/ClientsTablePage'
import { EntitiesTablePage } from '../pages/EntitiesTable/EntitiesTablePage'
import { SingleClientPage } from '../pages/SingleClient/SingleClientPage'
import { SingleEntityPage } from '../pages/SingleEntity/SingleEntityPage'
import { LoginPage } from '../pages/Login/LoginPage'
import { UsersTablePage } from '../pages/UsersTable/UsersTablePage'
import { STAGE } from '../constants/envs'
import { DebugPage } from '../pages/Debug/DebugPage'
import { GroupsTablePage } from '../pages/GroupsTable/GroupsTablePage'
import { PermissionsTablePage } from '../pages/PermissionsTable/PermissionsTablePage'
import { CreateIncidentPage } from '../pages/CreateIncident/CreateIncidentPage'
import { LinksListPage } from '../pages/LinksList/LinksListPage'
import { ErrorPage } from '../pages/ErrorPage'

const LoadWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const URLS_WITHOUT_AUTH = ['/login', '/error', '/unauthorized']
// subjected to change in the future
export const URLS_WITHOUT_MENU = URLS_WITHOUT_AUTH

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { initialAuthCheck, isAuthenticated } = store.CommunicationState

  const theme = useTheme()

  const [location] = useLocation()

  console.log('theme', theme)

  // eslint-disable-next-line react/prop-types
  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        component={(props) => {
          if (!initialAuthCheck) {
            return (
              <LoadWrap>
                <LoadingSpinner {...theme.loadingSpinner} width="48px" padding="0 0" thickness={4} />
              </LoadWrap>
            )
          } else if (isAuthenticated) {
            return <Component {...props} />
          } else {
            return <Redirect to="/login" />
          }
        }}
      />
    )
  }

  const menuVisible = !URLS_WITHOUT_MENU.includes(location)

  return (
    <RouterContainer>
      {menuVisible && <Menu />}

      <ContentWrap $menuVisible={menuVisible}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/unauthorized" component={ErrorPage} />

          <PrivateRoute path="/" component={ClientsTablePage} />
          <PrivateRoute path="/configurations/clients" component={ClientsTablePage} />
          <PrivateRoute path="/configurations/clients/:id" component={SingleClientPage} />

          <PrivateRoute path="/configurations/entities" component={EntitiesTablePage} />
          <PrivateRoute path="/configurations/entities/:id" component={SingleEntityPage} />

          <PrivateRoute path="/access-management/users" component={UsersTablePage} />
          <PrivateRoute path="/access-management/groups" component={GroupsTablePage} />
          <PrivateRoute path="/access-management/permissions" component={PermissionsTablePage} />

          <PrivateRoute path="/tools/create-incident" component={CreateIncidentPage} />
          <PrivateRoute path="/tools/links" component={LinksListPage} />

          {/* Local dev debug page */}
          {STAGE === 'local' && <Route path="/debug" component={DebugPage} />}

          <Route path="/error" component={ErrorPage} />

          {/* Default route in a switch */}
          <Route>
            <LoadWrap>
              <NotFoundPage />
            </LoadWrap>
          </Route>
        </Switch>
      </ContentWrap>
    </RouterContainer>
  )
})
