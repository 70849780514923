import { FC, PropsWithChildren, useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'wouter'
import { axiosInstance } from '../../methods/axiosConfig'
import { StoreContext } from '../App'
import useCheckMobile from '../../hooks/useCheckMobile'
import { StyledMenu, MenuNav, Overlay, PositionedHamburger, MenuBottom, StyledLogo } from './MobileMenu.styles'
import { LogoutIcon } from '../Icons/LogoutIcon'
import { LogsIcon } from '../Icons/LogsIcon'
import { MenuItem } from './MenuItem'
import { ThemeSwitcher } from '../ThemeSwitcher/ThemeSwitcher'
import { MenuItemIcon } from './MenuItemIcon'
import { MenuItemName } from './MenuItemName'
import { SignicatLogo } from '../Brandings/SignicatLogo'
import { useLocalStorage } from 'usehooks-ts'
import { ThemeIcon } from '../ThemeSwitcher/ThemeIcon'
import { HamburgerIcon } from '../Icons/HamburgerIcon'
import { CloseIcon } from '../Icons/CloseIcon'
import { useTheme } from 'styled-components'
import { UsersIcon } from '../Icons/UsersIcon'
import { STAGE } from '../../constants/envs'
import { SettingsIcon } from '../Icons/SettingsIcon'
import { PermsIcon } from '../Icons/PermsIcon'
import { PinkhubLogo } from '../Brandings/PinkhubLogo'
import { StarIcon } from '../Icons/StarIcon'

export const MobileMenu: FC<PropsWithChildren> = observer(() => {
  const theme = useTheme()
  const isMobileView = useCheckMobile()
  const store = useContext(StoreContext)
  const { openedPopupCount } = store.AppState
  const { currentUser } = store.CommunicationState

  //eslint-disable-next-line
  const [location, setLocation] = useLocation()
  const [menuOpened, setMenuOpened] = useLocalStorage('menuOpened', true)

  const toggleTheme = () => {
    store.AppState.toggleTheme()
  }

  const logout = useCallback(async () => {
    await axiosInstance.get(`logout`)

    store.CommunicationState.setAuthorization(false)

    if (isMobileView) setMenuOpened(false)

    setLocation('/login')
  }, [])

  const isAllowedToManageConfig = currentUser?.capabilities?.includes('CLIENT_CONFIG_MANAGEMENT')

  const isAllowedToManageAccess = currentUser?.capabilities?.includes('ACCESS_MANAGEMENT')

  const isAlowedIncidentManagement = currentUser?.capabilities?.includes('INCIDENT_MANAGEMENT')

  return (
    <>
      <PositionedHamburger $visible={!openedPopupCount}>
        {!menuOpened ? (
          <HamburgerIcon onClick={() => setMenuOpened(!menuOpened)}></HamburgerIcon>
        ) : (
          <CloseIcon onClick={() => setMenuOpened(!menuOpened)}></CloseIcon>
        )}
      </PositionedHamburger>

      <StyledMenu open={menuOpened}>
        <MenuNav>
          <StyledLogo $height="80px">
            <PinkhubLogo />
          </StyledLogo>

          {isAllowedToManageConfig && (
            <>
              <MenuItem path={['/', '/configurations/clients', '/configurations/clients/*']}>
                <MenuItemIcon>
                  <LogsIcon />
                </MenuItemIcon>
                <MenuItemName>Clients</MenuItemName>
              </MenuItem>
              <MenuItem path={['/configurations/entities', '/configurations/entities/*']}>
                <MenuItemIcon>
                  <LogsIcon />
                </MenuItemIcon>
                <MenuItemName>Entities</MenuItemName>
              </MenuItem>
            </>
          )}

          {isAllowedToManageAccess && (
            <>
              <MenuItem path="/access-management//users">
                <MenuItemIcon>
                  <UsersIcon />
                </MenuItemIcon>
                <MenuItemName>Users</MenuItemName>
              </MenuItem>
              <MenuItem path="/access-management//groups">
                <MenuItemIcon>
                  <UsersIcon />
                </MenuItemIcon>
                <MenuItemName>Groups</MenuItemName>
              </MenuItem>
              <MenuItem path="/access-management//permissions">
                <MenuItemIcon>
                  <PermsIcon />
                </MenuItemIcon>
                <MenuItemName>Permissions</MenuItemName>
              </MenuItem>
            </>
          )}

          {isAlowedIncidentManagement && (
            <MenuItem path="/tools/create-incident">
              <MenuItemIcon>
                <StarIcon />
              </MenuItemIcon>
              <MenuItemName>Create incident</MenuItemName>
            </MenuItem>
          )}
          <MenuItem path="/tools/links">
            <MenuItemIcon>
              <StarIcon />
            </MenuItemIcon>
            <MenuItemName>Links</MenuItemName>
          </MenuItem>

          {STAGE === 'local' && (
            <MenuItem path="/debug">
              <MenuItemIcon>
                <SettingsIcon />
              </MenuItemIcon>
              <MenuItemName>Debug</MenuItemName>
            </MenuItem>
          )}
        </MenuNav>

        <MenuBottom>
          <StyledLogo>
            <SignicatLogo />
          </StyledLogo>

          <ThemeSwitcher onClick={toggleTheme}>
            <ThemeIcon />
            <MenuItemName>{theme.name}</MenuItemName>
          </ThemeSwitcher>

          <MenuItem onClick={logout}>
            <MenuItemIcon>
              <LogoutIcon />
            </MenuItemIcon>
            <MenuItemName>Logout</MenuItemName>
          </MenuItem>
        </MenuBottom>
      </StyledMenu>

      <Overlay
        $visible={menuOpened}
        onClick={() => {
          setMenuOpened(false)
        }}
      />
    </>
  )
})
