import styled, { css } from 'styled-components'
import { Animatable } from '../../styles/generic.styles'

export const StyledMenu = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  height: 100%;
  min-height: 100%;
  position: fixed;
  right: 0;
  z-index: 3;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background-color: ${({ theme }) => theme.colors.quaternary};
  width: 100%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

  ${({ open }) =>
    open &&
    css`
      width: 300px;
    `}

  ${({ open }) =>
    !open &&
    css`
      width: 0;
    `}
  

  ${Animatable};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const StyledLogo = styled.div<{ $height?: string }>`
  width: calc(100% - 64px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: ${({ $height }) => $height || '50px'};

  svg {
    height: 100%;
  }
`

export const PositionedHamburger = styled.div<{ $visible?: boolean }>`
  position: fixed;
  top: 12px;
  right: 16px;
  z-index: 4;

  display: ${({ $visible }) => ($visible ? 'block' : 'none')};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const MenuNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  gap: 16px;
`

export const MenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 16px;
`

export const Overlay = styled.div<{
  $visible: boolean
}>`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  background: rgba(12, 1, 27, 0.59);
  backdrop-filter: blur(8px);

  ${({ $visible }) =>
    $visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  ${({ $visible }) =>
    !$visible &&
    css`
      visibility: hidden;
      opacity: 0;
    `}

    ${Animatable};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`
